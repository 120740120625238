/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  MoreVertical,
  Download,
  Trash,
  Edit,
  Move,
  Info,
  Loader2,
} from "lucide-react";
import { useFiles } from "../hooks/use_files";
import RenameFileDialog from "../components/modals/RenameFileDialog";
import MoveFileDialog from "../components/modals/MoveFileDialog";
import FileDetailsDialog from "../components/modals/FileDetailsDialog";
import { File } from "@/constants/file_types";

interface FileOptionsMenuProps {
  file: File;
}

export default function FileOptionsMenu({ file }: FileOptionsMenuProps) {
  const { moveToTrashFile, renameFile, moveFile } = useFiles();
  const [showRenameDialog, setShowRenameDialog] = useState(false);
  const [showMoveDialog, setShowMoveDialog] = useState(false);
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = async () => {
    try {
      setIsDownloading(true);

      const response = await fetch(file.service_url);
      if (!response.ok) throw new Error("Error al descargar el archivo");

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = file.file_name;
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error descargando el archivo:", error);
      // Aquí podrías mostrar un toast o notificación de error
    } finally {
      setIsDownloading(false);
    }
  };

  const handleDelete = async () => {
    setShowDeleteConfirmation(true);
  };

  const confirmDelete = async () => {
    await moveToTrashFile(file.file_id);
    setShowDeleteConfirmation(false);
  };

  const handleRename = async (newName: string) => {
    await renameFile(file.file_name, newName);
    setShowRenameDialog(false);
  };

  const handleMove = async (newPath: string) => {
    await moveFile(file.file_name, newPath);
    setShowMoveDialog(false);
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            size="icon"
            className="hover:bg-gray-100 transition-colors duration-200"
          >
            <MoreVertical className="h-5 w-5 text-gray-600" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56">
          <DropdownMenuItem
            onClick={handleDownload}
            disabled={isDownloading}
            className="flex items-center px-3 py-2 hover:bg-gray-100 transition-colors duration-200"
          >
            {isDownloading ? (
              <Loader2 className="mr-2 h-4 w-4 text-blue-500 animate-spin" />
            ) : (
              <Download className="mr-2 h-4 w-4 text-blue-500" />
            )}
            <span className="text-sm font-medium">
              {isDownloading ? "Descargando..." : "Descargar"}
            </span>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={handleDelete}
            className="flex items-center px-3 py-2 hover:bg-gray-100 transition-colors duration-200"
          >
            <Trash className="mr-2 h-4 w-4 text-red-500" />
            <span className="text-sm font-medium">Borrar</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => setShowRenameDialog(true)}
            className="flex items-center px-3 py-2 hover:bg-gray-100 transition-colors duration-200"
          >
            <Edit className="mr-2 h-4 w-4 text-green-500" />
            <span className="text-sm font-medium">Renombrar</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => setShowMoveDialog(true)}
            className="flex items-center px-3 py-2 hover:bg-gray-100 transition-colors duration-200"
          >
            <Move className="mr-2 h-4 w-4 text-purple-500" />
            <span className="text-sm font-medium">Mover</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => setShowDetailsDialog(true)}
            className="flex items-center px-3 py-2 hover:bg-gray-100 transition-colors duration-200"
          >
            <Info className="mr-2 h-4 w-4 text-yellow-500" />
            <span className="text-sm font-medium">Detalles</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog
        open={showDeleteConfirmation}
        onOpenChange={setShowDeleteConfirmation}
      >
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Confirmar eliminación</DialogTitle>
            <DialogDescription>
              ¿Estás seguro de que quieres mover{" "}
              <span className="font-semibold">{file.file_name}</span> a la
              papelera?
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="sm:justify-start">
            <Button
              type="button"
              variant="destructive"
              onClick={confirmDelete}
              className="w-full sm:w-auto"
            >
              Confirmar
            </Button>
            <Button
              type="button"
              variant="outline"
              onClick={() => setShowDeleteConfirmation(false)}
              className="w-full sm:w-auto mt-2 sm:mt-0"
            >
              Cancelar
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <RenameFileDialog
        isOpen={showRenameDialog}
        onClose={() => setShowRenameDialog(false)}
        onRename={handleRename}
        currentName={file.file_name}
      />

      <MoveFileDialog
        isOpen={showMoveDialog}
        onClose={() => setShowMoveDialog(false)}
        onMove={handleMove}
        currentPath={file.directory}
      />

      <FileDetailsDialog
        isOpen={showDetailsDialog}
        onClose={() => setShowDetailsDialog(false)}
        file={file}
      />
    </>
  );
}
