/* eslint-disable @typescript-eslint/no-explicit-any */
import { login } from "@/api/resources/auth";
import { jwtDecode } from "jwt-decode";

import { useState } from "react";
import { useCallServices } from "../../hooks";
import { useStore } from "@/packages/storage/zustand";

const Errors = {
  form: "Debes ingresar todos los datos",
};

interface JWTPayload {
  _id: string;
  Name: string;
  email: string;
  BirthDate: string;
  DocumentNumber: string;
  Role: string;
  Position: string;
  iat: number;
  exp: number;
}

export const useLogin = () => {
  const { callEndpoint, loading } = useCallServices();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setUser, setToken } = useStore();

  const initLogin = async () => {
    try {
      if (!email || !password) throw new Error(Errors.form);

      const response = await callEndpoint(login({ email, password }));

      if (response?.item?.token) {
        const token = response.item.token;
        const decodedToken = jwtDecode<JWTPayload>(token);

        // Guardamos en Zustand
        setToken(token);
        setUser(decodedToken);

        // Opcional: también puedes guardar en localStorage como backup
        localStorage.setItem("user", JSON.stringify(decodedToken));
        localStorage.setItem("token", token);
      }

      return { success: true };
    } catch (err: any) {
      console.log("this is the error:", err);

      let errorMessage = "Ha ocurrido un error. Por favor, inténtalo de nuevo.";

      if (err && typeof err === "object" && "message" in err) {
        const message = err.message;

        if (message.includes("User with email")) {
          errorMessage = "El usuario con este correo electrónico no existe.";
        } else if (message.includes("Password incorrect")) {
          errorMessage = "La contraseña es incorrecta.";
        } else {
          errorMessage = message;
        }
      }

      return { success: false, error: errorMessage };
    }
  };

  const handleEmail = (text: string) => setEmail(text);
  const handlePassword = (text: string) => setPassword(text);

  return {
    email,
    password,
    handleEmail,
    handlePassword,
    initLogin,
    loading,
  };
};
