import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import {
  Zap,
  UserIcon,
  MailIcon,
  LockIcon,
  EyeIcon,
  EyeOffIcon,
  CalendarIcon,
  CreditCardIcon,
  ArrowLeft,
} from "lucide-react";
import { useRegister } from "./use_register";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

export default function Register() {
  const {
    email,
    password,
    confirmPassword,
    name,
    document_number,
    birthdate,
    gender,
    usageType,
    handleEmail,
    handlePassword,
    handleConfirmPassword,
    handleName,
    handleDocument_number,
    handleBirthdate,
    handleGender,
    handleUsageType,
    initRegister,
    loading,
  } = useRegister();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptPrivacy, setAcceptPrivacy] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const result = await initRegister();
    if (result?.success) {
      // Aquí guardas los datos del usuario sin contraseña
      const userData = result.response;
      localStorage.setItem("user", JSON.stringify(userData));

      setShowAnimation(true);
      setTimeout(() => {
        setShowAnimation(false);
        navigate("/welcome", { replace: true });
      }, 2000);
    } else {
      console.log(result);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-blue-400 to-blue-600 p-4">
      <AnimatePresence>
        {showAnimation && (
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5 }}
            transition={{ duration: 0.5 }}
            className="fixed inset-0 flex items-center justify-center bg-white z-50"
          >
            <div className="flex flex-col items-center">
              <Zap
                className="w-20 h-20 text-yellow-400 stroke-current"
                strokeWidth={2}
              />
              <motion.div
                initial={{ width: 0 }}
                animate={{ width: "100%" }}
                transition={{ duration: 1.5, delay: 0.5 }}
                className="h-2 bg-gradient-to-r from-blue-600 to-green-400 mt-4"
                style={{ width: "200px" }}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <Card className="w-full max-w-md bg-white/90 backdrop-blur-sm">
        <form onSubmit={handleSubmit}>
          <CardHeader className="space-y-1">
            <Button
              variant="ghost"
              size="icon"
              onClick={() => navigate("/")}
              className="absolute left-0 top-0 text-blue-600 hover:text-blue-800"
            >
              <ArrowLeft className="h-6 w-6" />
              <span className="sr-only">Volver al inicio</span>
            </Button>
            <CardTitle className="text-2xl sm:text-3xl font-bold text-center text-blue-900">
              <div className="flex items-center justify-center space-x-2">
                <Zap
                  className="w-8 h-8 sm:w-10 sm:h-10 text-yellow-400 stroke-current"
                  strokeWidth={3}
                />
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-green-400">
                  ZapTrack
                </span>
              </div>
            </CardTitle>
            <CardDescription className="text-center text-blue-700">
              Crea tu cuenta en ZapTrack
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-4">
            <div className="space-y-2">
              <Label
                htmlFor="nombre"
                className="text-sm font-medium text-gray-700"
              >
                Nombre completo
              </Label>
              <div className="relative">
                <UserIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-500" />
                <Input
                  id="nombre"
                  placeholder="Ingresa tu nombre completo"
                  type="text"
                  className="pl-10 border-blue-300 focus:border-green-400 focus:ring-green-400"
                  value={name}
                  onChange={(e) => handleName(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="space-y-2">
              <Label
                htmlFor="documento"
                className="text-sm font-medium text-gray-700"
              >
                Documento de identidad
              </Label>
              <div className="relative">
                <CreditCardIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-500" />
                <Input
                  id="documento"
                  placeholder="Ingresa tu documento de identidad"
                  type="text"
                  className="pl-10 border-blue-300 focus:border-green-400 focus:ring-green-400"
                  value={document_number}
                  onChange={(e) => handleDocument_number(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="space-y-2">
              <Label
                htmlFor="fecha-nacimiento"
                className="text-sm font-medium text-gray-700"
              >
                Fecha de nacimiento
              </Label>
              <div className="relative">
                <CalendarIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-500" />
                <Input
                  id="fecha-nacimiento"
                  type="date"
                  className="pl-10 border-blue-300 focus:border-green-400 focus:ring-green-400"
                  value={birthdate}
                  onChange={(e) => handleBirthdate(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="space-y-2">
              <Label
                htmlFor="genero"
                className="text-sm font-medium text-gray-700"
              >
                Género
              </Label>
              <RadioGroup
                id="genero"
                className="flex flex-wrap gap-4"
                value={gender}
                onValueChange={handleGender}
              >
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="hombre" id="hombre" />
                  <Label htmlFor="hombre">Hombre</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="mujer" id="mujer" />
                  <Label htmlFor="mujer">Mujer</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="otro" id="otro" />
                  <Label htmlFor="otro">Otro</Label>
                </div>
              </RadioGroup>
            </div>
            <div className="space-y-2">
              <Label
                htmlFor="tipo-uso"
                className="text-sm font-medium text-gray-700"
              >
                Tipo de uso
              </Label>
              <Select value={usageType} onValueChange={handleUsageType}>
                <SelectTrigger className="w-full border-blue-300 focus:border-green-400 focus:ring-green-400">
                  <SelectValue placeholder="Selecciona el tipo de uso" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="Personal">Personal</SelectItem>
                  <SelectItem value="Business">Empresarial</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="space-y-2">
              <Label
                htmlFor="email"
                className="text-sm font-medium text-gray-700"
              >
                Correo electrónico
              </Label>
              <div className="relative">
                <MailIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-500" />
                <Input
                  id="email"
                  placeholder="tu@email.com"
                  type="email"
                  className="pl-10 border-blue-300 focus:border-green-400 focus:ring-green-400"
                  value={email}
                  onChange={(e) => handleEmail(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="space-y-2">
              <Label
                htmlFor="password"
                className="text-sm font-medium text-gray-700"
              >
                Contraseña
              </Label>
              <div className="relative">
                <LockIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-500" />
                <Input
                  id="password"
                  placeholder="••••••••"
                  type={showPassword ? "text" : "password"}
                  className="pl-10 pr-10 border-blue-300 focus:border-green-400 focus:ring-green-400"
                  value={password}
                  onChange={(e) => handlePassword(e.target.value)}
                  required
                />
                <Button
                  type="button"
                  variant="ghost"
                  size="icon"
                  className="absolute right-0 top-0 h-full px-3 text-blue-500 hover:text-blue-600"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <EyeOffIcon className="h-4 w-4" />
                  ) : (
                    <EyeIcon className="h-4 w-4" />
                  )}
                  <span className="sr-only">
                    {showPassword ? "Ocultar contraseña" : "Mostrar contraseña"}
                  </span>
                </Button>
              </div>
            </div>
            <div className="space-y-2">
              <Label
                htmlFor="confirm-password"
                className="text-sm font-medium text-gray-700"
              >
                Confirmar contraseña
              </Label>
              <div className="relative">
                <LockIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-500" />
                <Input
                  id="confirm-password"
                  placeholder="••••••••"
                  type={showConfirmPassword ? "text" : "password"}
                  className="pl-10 pr-10 border-blue-300 focus:border-green-400 focus:ring-green-400"
                  value={confirmPassword}
                  onChange={(e) => handleConfirmPassword(e.target.value)}
                  required
                />
                <Button
                  type="button"
                  variant="ghost"
                  size="icon"
                  className="absolute right-0 top-0 h-full px-3 text-blue-500 hover:text-blue-600"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? (
                    <EyeOffIcon className="h-4 w-4" />
                  ) : (
                    <EyeIcon className="h-4 w-4" />
                  )}
                  <span className="sr-only">
                    {showConfirmPassword
                      ? "Ocultar contraseña"
                      : "Mostrar contraseña"}
                  </span>
                </Button>
              </div>
            </div>
            <div className="space-y-2">
              <div className="flex items-center space-x-2">
                <Checkbox
                  id="terms"
                  checked={acceptTerms}
                  onCheckedChange={(checked) =>
                    setAcceptTerms(checked as boolean)
                  }
                />
                <label
                  htmlFor="terms"
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  Acepto los{" "}
                  <a href="/terms" className="text-blue-600 hover:underline">
                    términos y condiciones
                  </a>
                </label>
              </div>
              <div className="flex items-center space-x-2">
                <Checkbox
                  id="privacy"
                  checked={acceptPrivacy}
                  onCheckedChange={(checked) =>
                    setAcceptPrivacy(checked as boolean)
                  }
                />
                <label
                  htmlFor="privacy"
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  Acepto la{" "}
                  <a href="/privacy" className="text-blue-600 hover:underline">
                    política de privacidad
                  </a>
                </label>
              </div>
            </div>
            <div className="text-sm text-blue-700 bg-blue-100 p-3 rounded-md">
              Al registrarte, obtendrás una prueba gratuita de 1 GB de
              almacenamiento por un mes.
            </div>
          </CardContent>
          <CardFooter className="flex flex-col space-y-2">
            <Button
              className="w-full bg-gradient-to-r from-blue-600 to-green-400 hover:from-blue-700 hover:to-green-500 text-white"
              disabled={!acceptTerms || !acceptPrivacy || loading}
              type="submit"
            >
              {loading ? "Cargando..." : "Registrarse"}
            </Button>
            <div className="text-center text-sm text-blue-700">
              ¿Ya tienes una cuenta?{" "}
              <a href="/login" className="text-green-500 hover:underline">
                Inicia sesión
              </a>
            </div>
          </CardFooter>
        </form>
      </Card>
    </div>
  );
}
