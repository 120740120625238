import { build as buildDownGetFiles } from "@/api/resources/storage/services/downGetFiles";
import { build as buildUpload } from "@/api/resources/storage/services/upload";
import { build as buildMoveFile } from "@/api/resources/storage/services/moveFile";
import { build as buildEmptyTrash } from "@/api/resources/storage/services/emptyTrash";
import { build as buildMoveToTrash } from "@/api/resources/storage/services/moveToTrash";
import { build as buildRestoreFromTrash } from "@/api/resources/storage/services/restoreFromTrash";
import { build as buildCreateDirectory } from "@/api/resources/storage/services/createDirectory";
import { build as buildUploads } from "@/api/resources/storage/services/uploads";
import API_BASE_PATHS from "@/constants/api_paths";
import { abortController } from "@/packages/abort_controller";
import {
  HttpHandler,
  axios,
  setInterceptors,
} from "@/packages/http/http_client";
export type Dependencies = {
  http: HttpHandler;
  abortController: () => AbortController;
};
const http = axios.create({
  baseURL: API_BASE_PATHS.storage,
});
setInterceptors([http]);

const dependencies: Dependencies = {
  http,
  abortController,
};
export const downGetFiles = buildDownGetFiles(dependencies);
export const upload = buildUpload(dependencies);
export const uploads = buildUploads(dependencies);
export const createDirectory = buildCreateDirectory(dependencies);
export const moveFile = buildMoveFile(dependencies);
export const moveToTrash = buildMoveToTrash(dependencies);
export const restoreFromTrash = buildRestoreFromTrash(dependencies);
export const emptyTrash = buildEmptyTrash(dependencies);

export const service = {
  downGetFiles,
  upload,
  uploads,
  createDirectory,
  moveFile,
  moveToTrash,
  restoreFromTrash,
  emptyTrash,
};
