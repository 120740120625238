/* eslint-disable @typescript-eslint/no-explicit-any */
import { Login } from "../types/autorization";
import { Dependencies } from "..";

export const build = ({ http, abortController }: Dependencies) => {
  const LOGIN_PATH = "/login";
  const execute = (data: Login) => {
    const controller = abortController();
    return {
      controller,
      response: http.post<any>(`${LOGIN_PATH}`, data, {
        signal: controller.signal,
      }),
    };
  };
  return execute;
};
