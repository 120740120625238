/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useRef } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import Navbar from "@/components/narbar/narbar";
import { Plus, Trash2 } from "lucide-react";
import { Progress } from "@/components/ui/progress";
import { useFiles } from "./hooks/use_files";
import { useUploads } from "./hooks/use_uploads";
import FileUploadArea from "./components/FileUploadArea";
import FileList from "./components/FileList";
import CreateFolderDialog from "./components/CreateFolderDialog";
import TrashDialog from "./components/TrashDialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import FloatingUploadProgress from "./components/modals/FloatingUploadProgress";

export default function Dashboard() {
  const [showUploadArea, setShowUploadArea] = useState(false);
  const [showCreateFolderDialog, setShowCreateFolderDialog] = useState(false);
  const [showTrashDialog, setShowTrashDialog] = useState(false);
  const [currentPath, setCurrentPath] = useState("/");
  const dragCounter = useRef(0);

  const {
    files,
    trashFiles,
    totalStorageUsed,
    directories,
    getfilesForUser,
    createFolder,
    moveToTrashFile,
    restoreFromTrashFile,
    emptyTrashFiles,
  } = useFiles();

  const { uploadFiles, uploadProgress } = useUploads();

  const handleDirectoryClick = (path: string) => {
    setCurrentPath(path);
  };

  const storageLimit = 1024 * 1024 * 1024;
  const storageUsagePercentage = (totalStorageUsed / storageLimit) * 100;

  const onDragEnter = useCallback((event: React.DragEvent) => {
    event.preventDefault();
    dragCounter.current += 1;
    if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
      setShowUploadArea(true);
    }
  }, []);

  const onDragLeave = useCallback((event: React.DragEvent) => {
    event.preventDefault();
    dragCounter.current -= 1;
    if (dragCounter.current === 0) {
      setShowUploadArea(false);
    }
  }, []);

  const onDragOver = useCallback((event: React.DragEvent) => {
    event.preventDefault();
  }, []);

  const onDrop = useCallback((event: React.DragEvent) => {
    event.preventDefault();
    dragCounter.current = 0;
    setShowUploadArea(false);
    const droppedFiles = Array.from(event.dataTransfer.files);
    if (droppedFiles.length > 0) {
      handleFiles(droppedFiles);
    }
  }, []);

  const handleFiles = (selectedFiles: File[]) => {
    uploadFiles(selectedFiles, getfilesForUser);
  };

  const handleCreateFolder = useCallback(
    (folderName: string) => {
      createFolder(folderName);
      setShowCreateFolderDialog(false);
    },
    [createFolder]
  );

  const handleTrashDialogClose = useCallback(() => {
    setShowTrashDialog(false);
  }, []);

  const handleCreateFolderDialogClose = useCallback(() => {
    setShowCreateFolderDialog(false);
  }, []);

  return (
    <div className="flex flex-col h-screen">
      <Navbar />

      <main
        className="flex-1 overflow-y-auto bg-gray-50"
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        onDrop={onDrop}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          {/* Mobile Search */}
          <div className="md:hidden mb-4">
            <Input
              type="search"
              placeholder="Buscar en ZapTrack"
              className="w-full"
            />
          </div>

          {/* Header Section */}
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-semibold text-gray-900">
              Mis archivos
            </h1>
            <div className="flex space-x-2">
              <Button
                className="bg-gradient-to-r from-blue-600 to-green-400 hover:from-blue-700 hover:to-green-500"
                onClick={() => setShowUploadArea(true)}
              >
                <Plus className="mr-2 h-4 w-4" /> Subir archivo
              </Button>
              <Button
                variant="outline"
                onClick={() => setShowCreateFolderDialog(true)}
              >
                <Plus className="mr-2 h-4 w-4" /> Nueva carpeta
              </Button>
              <Button
                variant="outline"
                onClick={() => setShowTrashDialog(true)}
              >
                <Trash2 className="mr-2 h-4 w-4" /> Papelera
              </Button>
            </div>
          </div>

          {/* Storage Usage */}
          <div className="mb-6">
            <div className="flex justify-between items-center mb-2">
              <span className="text-sm font-medium text-gray-700">
                Almacenamiento usado
              </span>
              <span className="text-sm font-medium text-gray-700">
                {(totalStorageUsed / (1024 * 1024)).toFixed(2)} MB / 1 GB
              </span>
            </div>
            <Progress value={storageUsagePercentage} className="w-full" />
          </div>

          {/* File Upload Area */}
          <FileUploadArea
            show={showUploadArea}
            onClose={() => setShowUploadArea(false)}
            onFileSelect={handleFiles}
          />

          {/* File Management Tabs */}
          <Tabs defaultValue="files" className="w-full">
            <TabsList>
              <TabsTrigger value="files">Archivos</TabsTrigger>
              <TabsTrigger value="trash">Papelera</TabsTrigger>
            </TabsList>

            {/* Files Tab */}
            <TabsContent value="files">
              <FileList
                files={files}
                directories={directories} // Add this prop
                currentPath={currentPath} // Add this prop
                onDirectoryClick={handleDirectoryClick} // Add this prop
                onMoveToTrash={moveToTrashFile}
              />
            </TabsContent>

            {/* Trash Tab */}
            <TabsContent value="trash">
              <FileList
                files={trashFiles}
                directories={[]}
                onRestoreFromTrash={restoreFromTrashFile}
                isTrash
              />
              {trashFiles.length > 0 && (
                <Button
                  variant="destructive"
                  onClick={emptyTrashFiles}
                  className="mt-4"
                >
                  Vaciar Papelera
                </Button>
              )}
            </TabsContent>
          </Tabs>
        </div>
      </main>

      {/* Floating Upload Progress */}
      <FloatingUploadProgress uploadProgress={uploadProgress} />

      {/* Dialogs */}
      <CreateFolderDialog
        isOpen={showCreateFolderDialog}
        onClose={handleCreateFolderDialogClose}
        onCreateFolder={handleCreateFolder}
      />

      <TrashDialog
        isOpen={showTrashDialog}
        onClose={handleTrashDialogClose}
        trashFiles={trashFiles}
        onRestore={restoreFromTrashFile}
        onEmptyTrash={emptyTrashFiles}
      />
    </div>
  );
}
