import { useState } from "react";
import { DragDropContext, DropResult } from "@hello-pangea/dnd";
import Navbar from "@/components/narbar/narbar";
import { Board } from "./types";
import { initialBoards } from "./use_board";
import ProjectSidebar from "./components/ProjectSidebar";
import BoardContent from "./components/BoardContent";
import NewProjectModal from "./components/NewProjectModal";
import ProjectTabs from "./components/ProjectTabs";

export default function BoardManager() {
  const [boards, setBoards] = useState<Board[]>(initialBoards);
  const [activeBoard, setActiveBoard] = useState<Board>(boards[0]);
  const [isNewProjectModalOpen, setIsNewProjectModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("backlog");

  const onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId, type } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (type === "column") {
      const newColumnOrder = Array.from(activeBoard.columnOrder);
      newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, draggableId);

      setActiveBoard((prevBoard) => ({
        ...prevBoard,
        columnOrder: newColumnOrder,
      }));
      return;
    }

    const startColumn = activeBoard.columns[source.droppableId];
    const finishColumn = activeBoard.columns[destination.droppableId];

    if (startColumn === finishColumn) {
      const newTasks = Array.from(startColumn.tasks);
      newTasks.splice(source.index, 1);
      newTasks.splice(destination.index, 0, startColumn.tasks[source.index]);

      const newColumn = {
        ...startColumn,
        tasks: newTasks,
      };

      setActiveBoard((prevBoard) => ({
        ...prevBoard,
        columns: {
          ...prevBoard.columns,
          [newColumn.id]: newColumn,
        },
      }));
    } else {
      const startTasks = Array.from(startColumn.tasks);
      const [movedTask] = startTasks.splice(source.index, 1);
      const newStartColumn = {
        ...startColumn,
        tasks: startTasks,
      };

      const finishTasks = Array.from(finishColumn.tasks);
      finishTasks.splice(destination.index, 0, movedTask);
      const newFinishColumn = {
        ...finishColumn,
        tasks: finishTasks,
      };

      setActiveBoard((prevBoard) => ({
        ...prevBoard,
        columns: {
          ...prevBoard.columns,
          [newStartColumn.id]: newStartColumn,
          [newFinishColumn.id]: newFinishColumn,
        },
      }));
    }
  };

  const addNewBoard = (title: string) => {
    const newBoard: Board = {
      id: `board-${Date.now()}`,
      title: title,
      type: "personal",
      columns: {},
      columnOrder: [],
    };
    setBoards([...boards, newBoard]);
    setActiveBoard(newBoard);
  };

  const updateBoard = (updatedBoard: Board) => {
    setBoards(
      boards.map((board) =>
        board.id === updatedBoard.id ? updatedBoard : board
      )
    );
    if (activeBoard.id === updatedBoard.id) {
      setActiveBoard(updatedBoard);
    }
  };

  const deleteBoard = (boardId: string) => {
    setBoards(boards.filter((board) => board.id !== boardId));
    if (activeBoard.id === boardId) {
      setActiveBoard(boards[0]);
    }
  };

  const handleTabChange = (tabId: string) => {
    setActiveTab(tabId);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "backlog":
        return (
          <DragDropContext onDragEnd={onDragEnd}>
            <BoardContent
              activeBoard={activeBoard}
              setActiveBoard={setActiveBoard}
            />
          </DragDropContext>
        );
      case "team":
        return (
          <div className="flex-1 p-6">
            Team Capacity Content (To be implemented)
          </div>
        );
      case "iteration":
        return (
          <div className="flex-1 p-6">
            Current Iteration Content (To be implemented)
          </div>
        );
      case "roadmap":
        return (
          <div className="flex-1 p-6">Roadmap Content (To be implemented)</div>
        );
      case "items":
        return (
          <div className="flex-1 p-6">My Items Content (To be implemented)</div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Navbar />
      <div className="flex flex-col h-screen overflow-hidden">
        <ProjectTabs
          activeTab={activeTab}
          onTabChange={handleTabChange}
          className="border-b"
        />
        <div className="flex flex-1 overflow-hidden">
          <ProjectSidebar
            boards={boards}
            activeBoard={activeBoard}
            setActiveBoard={setActiveBoard}
            onNewProject={() => setIsNewProjectModalOpen(true)}
            onUpdateBoard={updateBoard}
            onDeleteBoard={deleteBoard}
          />
          {renderTabContent()}
        </div>
      </div>
      <NewProjectModal
        isOpen={isNewProjectModalOpen}
        onClose={() => setIsNewProjectModalOpen(false)}
        onCreateProject={addNewBoard}
      />
    </>
  );
}
