/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dependencies } from "..";

export const build = ({ http, abortController }: Dependencies) => {
  const PATH = "/down/files/";
  const execute = (userID: string) => {
    const controller = abortController();

    return {
      response: http.get<any>(`${PATH}${userID}`),
      controller,
    };
  };
  return execute;
};
