import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";

interface MoveFileDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onMove: (newPath: string) => void;
  currentPath: string;
}

export default function MoveFileDialog({
  isOpen,
  onClose,
  onMove,
  currentPath,
}: MoveFileDialogProps) {
  const [newPath, setNewPath] = useState(currentPath);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (newPath.trim() && newPath !== currentPath) {
      onMove(newPath.trim());
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Mover archivo</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit}>
          <Input
            value={newPath}
            onChange={(e) => setNewPath(e.target.value)}
            placeholder="Nueva ruta del archivo"
            className="my-4"
          />
          <DialogFooter>
            <Button type="button" variant="outline" onClick={onClose}>
              Cancelar
            </Button>
            <Button type="submit">Mover</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
