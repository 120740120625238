import API_PATHS from "@/constants/api_paths";
import axios, { AxiosInstance } from "axios";
import { abortController } from "@/packages/abort_controller";

import { build as buildRegister } from "@/api/resources/users/services/register";

export type HttpHandler = AxiosInstance;

export type Dependencies = {
  http: HttpHandler;
  abortController: () => AbortController;
};

const httpAuth = axios.create({
  baseURL: API_PATHS.users,
});

const dependenciesAuth: Dependencies = {
  http: httpAuth,
  abortController,
};

export const register = buildRegister(dependenciesAuth);

const service = {
  register,
};

export default service;
