/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from "react";
import { useCallServices } from "@/hooks/index";
import { useUserIDFromToken } from "@/hooks/useUserIDFromToken";
import { useErrorHandler } from "@/api/handlers/handlerError";
import { upload } from "@/api/resources/storage";

export const useUploads = () => {
  const { callEndpoint } = useCallServices();
  const handleError = useErrorHandler();
  const userID = useUserIDFromToken();
  const [uploadProgress, setUploadProgress] = useState<{
    [key: string]: number;
  }>({});

  const uploadFiles = async (files: File[], onComplete: () => void) => {
    const uploadPromises = files.map((file) => uploadSingleFile(file));
    await Promise.all(uploadPromises);
    onComplete();
  };

  const uploadSingleFile = async (file: File) => {
    const fileId = `${Date.now()}-${file.name}`;
    try {
      setUploadProgress((prev) => ({ ...prev, [fileId]: 0 }));

      const formData = new FormData();
      formData.append("file", file);

      const result = await callEndpoint(upload(userID!, file));

      console.log(`File ${file.name} uploaded successfully:`, result);

      setUploadProgress((prev) => ({ ...prev, [fileId]: 100 }));

      return result;
    } catch (error) {
      handleError(error);
      setUploadProgress((prev) => {
        const { [fileId]: removed, ...rest } = prev;
        return rest;
      });
    }
  };

  return { uploadFiles, uploadSingleFile, uploadProgress };
};
