import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { FileIcon, Calendar, HardDrive, FolderIcon } from "lucide-react";
import { formatFileSize } from "@/packages/utils/formatFileSize";

interface FileDetailsDialogProps {
  isOpen: boolean;
  onClose: () => void;
  file: {
    file_name: string;
    file_size: number;
    directory: string;
    upload_date: string;
  };
}

export default function FileDetailsDialog({
  isOpen,
  onClose,
  file,
}: FileDetailsDialogProps) {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[600px] max-h-[80vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="text-xl font-bold">
            Detalles del archivo
          </DialogTitle>
        </DialogHeader>
        <div className="mt-4 space-y-4">
          <div className="flex items-center space-x-3">
            <div className="bg-primary/10 p-2 rounded-full">
              <FileIcon className="h-4 w-4 text-primary" />
            </div>
            <div>
              <p className="text-xs font-medium text-gray-500">
                Nombre del archivo
              </p>
              <p className="text-sm font-semibold">{file.file_name}</p>
            </div>
          </div>
          <div className="flex items-center space-x-3">
            <div className="bg-primary/10 p-2 rounded-full">
              <HardDrive className="h-4 w-4 text-primary" />
            </div>
            <div>
              <p className="text-xs font-medium text-gray-500">
                Tamaño del archivo
              </p>
              <p className="text-sm font-semibold">
                {formatFileSize(file.file_size)}
              </p>
            </div>
          </div>
          <div className="flex items-center space-x-3">
            <div className="bg-primary/10 p-2 rounded-full">
              <FolderIcon className="h-4 w-4 text-primary" />
            </div>
            <div>
              <p className="text-xs font-medium text-gray-500">Directorio</p>
              <p className="text-sm font-semibold">{file.directory}</p>
            </div>
          </div>
          <div className="flex items-center space-x-3">
            <div className="bg-primary/10 p-2 rounded-full">
              <Calendar className="h-4 w-4 text-primary" />
            </div>
            <div>
              <p className="text-xs font-medium text-gray-500">
                Fecha de subida
              </p>
              <p className="text-sm font-semibold">
                {new Date(file.upload_date).toLocaleString()}
              </p>
            </div>
          </div>
        </div>
        <DialogFooter className="mt-6">
          <Button onClick={onClose}>Cerrar</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
