import { useState } from "react";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { MoreVertical, Pencil, Trash, Archive } from "lucide-react";
import { Board } from "../types";
import { Input } from "@/components/ui/input";

interface ProjectSidebarProps {
  boards: Board[];
  activeBoard: Board;
  setActiveBoard: (board: Board) => void;
  onNewProject: () => void;
  onUpdateBoard: (board: Board) => void;
  onDeleteBoard: (boardId: string) => void;
}

export default function ProjectSidebar({
  boards,
  activeBoard,
  setActiveBoard,
  onNewProject,
  onUpdateBoard,
  onDeleteBoard,
}: ProjectSidebarProps) {
  const [editingBoardId, setEditingBoardId] = useState<string | null>(null);
  const [editedBoardTitle, setEditedBoardTitle] = useState("");

  const handleEditBoard = (board: Board) => {
    setEditingBoardId(board.id);
    setEditedBoardTitle(board.title);
  };

  const handleSaveEdit = (board: Board) => {
    onUpdateBoard({ ...board, title: editedBoardTitle });
    setEditingBoardId(null);
  };

  return (
    <aside className="w-64 bg-gray-100 p-4 overflow-y-auto">
      <h2 className="text-xl font-bold mb-4">Proyectos</h2>
      <ul>
        {boards.map((board) => (
          <li
            key={board.id}
            className={`mb-2 p-2 rounded cursor-pointer ${
              board.id === activeBoard.id ? "bg-blue-100" : "hover:bg-gray-200"
            }`}
          >
            <div className="flex justify-between items-center">
              {editingBoardId === board.id ? (
                <Input
                  value={editedBoardTitle}
                  onChange={(e) => setEditedBoardTitle(e.target.value)}
                  onBlur={() => handleSaveEdit(board)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSaveEdit(board);
                    }
                  }}
                  className="w-full"
                />
              ) : (
                <span onClick={() => setActiveBoard(board)}>{board.title}</span>
              )}
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" size="sm">
                    <MoreVertical className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem onSelect={() => handleEditBoard(board)}>
                    <Pencil className="mr-2 h-4 w-4" />
                    Renombrar
                  </DropdownMenuItem>
                  <DropdownMenuItem onSelect={() => onDeleteBoard(board.id)}>
                    <Trash className="mr-2 h-4 w-4" />
                    Eliminar
                  </DropdownMenuItem>
                  <DropdownMenuItem>
                    <Archive className="mr-2 h-4 w-4" />
                    Archivar
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </li>
        ))}
      </ul>
      <Button onClick={onNewProject} className="mt-4 w-full">
        Nuevo Proyecto
      </Button>
    </aside>
  );
}
