/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dependencies } from "..";

export const build = ({ http, abortController }: Dependencies) => {
  const PATH = "/file-directory";
  const execute = (
    userID: string,
    directoryName: string,
    parentDirectory: string
  ) => {
    const controller = abortController();

    return {
      response: http.post<any>(`${PATH}/directory`, {
        userID,
        directoryName,
        parentDirectory,
      }),
      controller,
    };
  };
  return execute;
};
