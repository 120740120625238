/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useErrorHandler } from "@/api/handlers/handlerError";
import {
  downGetFiles,
  createDirectory,
  moveToTrash,
  restoreFromTrash,
  emptyTrash,
} from "@/api/resources/storage";
import { useState, useCallback, useEffect, useRef } from "react";
import { useCallServices } from "@/hooks/index";
import { useUserIDFromToken } from "@/hooks/useUserIDFromToken";
import { File } from "@/constants/file_types";

export const useFiles = () => {
  const { callEndpoint } = useCallServices();
  const [files, setFiles] = useState<File[]>([]);
  const [trashFiles, setTrashFiles] = useState<any[]>([]);
  const [totalStorageUsed, setTotalStorageUsed] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [directories, setDirectories] = useState<any[]>([]);
  const handleError = useErrorHandler();
  const userID = useUserIDFromToken();

  const isLoadingRef = useRef(false);

  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const getfilesForUser = useCallback(async () => {
    if (!userID || isLoadingRef.current || !isMounted.current) return;

    isLoadingRef.current = true;
    setIsLoading(true);

    try {
      const response = await callEndpoint(downGetFiles(userID));

      setDirectories(
        Array.isArray(response.directories) ? response.directories : []
      );

      setDirectories(response.directories);

      if (isMounted.current) {
        setFiles(
          response.files.filter((file: File) => file.directory !== "/.trash")
        );
        setTrashFiles(
          response.files.filter((file: File) => file.directory === "/trash")
        );
        setTotalStorageUsed(response.total_storage_used);
      }
    } catch (error) {
      if (isMounted.current) {
        handleError(error);
      }
    } finally {
      isLoadingRef.current = false;
      if (isMounted.current) {
        setIsLoading(false);
      }
    }
  }, [userID, callEndpoint, handleError]);

  useEffect(() => {
    if (userID) {
      getfilesForUser();
    }
  }, [userID]);

  const performFileOperation = useCallback(
    async (operation: () => Promise<void>) => {
      if (isLoadingRef.current || !isMounted.current) return;

      try {
        await operation();
        await getfilesForUser();
      } catch (error) {
        if (isMounted.current) {
          handleError(error);
        }
      }
    },
    [getfilesForUser, handleError]
  );

  const createFolder = useCallback(
    async (folderName: string, parentDirectory: string = "/") => {
      return performFileOperation(() =>
        callEndpoint(createDirectory(userID!, folderName, parentDirectory))
      );
    },
    [userID, callEndpoint, performFileOperation]
  );

  const moveToTrashFile = useCallback(
    async (fileId: string) => {
      return performFileOperation(() =>
        callEndpoint(moveToTrash(userID!, fileId))
      );
    },
    [userID, callEndpoint, performFileOperation]
  );

  const restoreFromTrashFile = useCallback(
    async (fileId: string) => {
      return performFileOperation(() =>
        callEndpoint(restoreFromTrash(userID!, fileId))
      );
    },
    [userID, callEndpoint, performFileOperation]
  );

  const emptyTrashFiles = useCallback(async () => {
    return performFileOperation(() => callEndpoint(emptyTrash(userID!)));
  }, [userID, callEndpoint, performFileOperation]);

  return {
    files,
    trashFiles,
    totalStorageUsed,
    directories,
    getfilesForUser,
    createFolder,
    moveToTrashFile,
    restoreFromTrashFile,
    emptyTrashFiles,
    isLoading,
  };
};
