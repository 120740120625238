/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useState, useEffect } from "react";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { XCircle, X } from "lucide-react";
import { Button } from "@/components/ui/button";

interface ErrorNotificationProps {
  message: string;
  title?: string;
  duration?: number;
  onClose?: () => void;
}

export default function ErrorNotification({
  message,
  title,
  duration = 5000,
  onClose,
}: ErrorNotificationProps) {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (duration > 0) {
      const timer = setTimeout(() => {
        setIsVisible(false);
        onClose && onClose();
      }, duration);
      return () => clearTimeout(timer);
    }
  }, [duration, onClose]);

  const handleClose = () => {
    setIsVisible(false);
    onClose && onClose();
  };

  if (!isVisible) return null;

  return (
    <Alert variant="destructive" className="mb-4">
      <XCircle className="h-4 w-4" />
      <AlertTitle className="flex items-center justify-between">
        {title}
        <Button
          variant="ghost"
          size="icon"
          className="h-4 w-4 p-0"
          onClick={handleClose}
        >
          <X className="h-4 w-4" />
          <span className="sr-only">Cerrar</span>
        </Button>
      </AlertTitle>
      <AlertDescription>{message}</AlertDescription>
    </Alert>
  );
}
