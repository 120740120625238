import { ScrollArea } from "@/components/ui/scroll-area";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

export default function Terms() {
  return (
    <div className="container mx-auto py-8 px-4">
      <Card className="w-full max-w-4xl mx-auto">
        <CardHeader>
          <CardTitle className="text-2xl md:text-3xl font-bold text-center text-blue-900">
            Términos y Condiciones de ZapTrack
          </CardTitle>
        </CardHeader>
        <CardContent>
          <ScrollArea className="h-[60vh] md:h-[70vh] overflow-auto pr-4">
            <div className="space-y-6 text-sm md:text-base">
              <section>
                <h2 className="text-xl font-semibold mb-2">
                  1. Aceptación de los Términos
                </h2>
                <p>
                  Al acceder y utilizar los servicios de ZapTrack, usted acepta
                  estar sujeto a estos Términos y Condiciones, todas las leyes y
                  regulaciones aplicables, y acepta que es responsable del
                  cumplimiento de las leyes locales aplicables en Colombia.
                </p>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-2">
                  2. Uso de la Licencia
                </h2>
                <p>
                  Se le otorga una licencia limitada para acceder y hacer uso
                  personal de ZapTrack. Esta licencia no le permite: (a)
                  modificar o copiar los materiales; (b) usar los materiales
                  para cualquier propósito comercial o para exhibición pública;
                  (c) intentar descompilar o aplicar ingeniería inversa a
                  cualquier software contenido en ZapTrack; (d) eliminar
                  cualquier derecho de autor u otras notaciones de propiedad de
                  los materiales; o (e) transferir los materiales a otra persona
                  o "reflejar" los materiales en cualquier otro servidor.
                </p>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-2">
                  3. Exención de Responsabilidad
                </h2>
                <p>
                  Los materiales en ZapTrack se proporcionan "tal cual".
                  ZapTrack no ofrece garantías, expresas o implícitas, y por la
                  presente renuncia y niega todas las demás garantías,
                  incluyendo, sin limitación, garantías implícitas o condiciones
                  de comerciabilidad, idoneidad para un propósito particular, o
                  no infracción de propiedad intelectual u otra violación de
                  derechos.
                </p>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-2">4. Limitaciones</h2>
                <p>
                  En ningún caso ZapTrack o sus proveedores serán responsables
                  por cualquier daño (incluyendo, sin limitación, daños por
                  pérdida de datos o beneficio, o debido a interrupción del
                  negocio) que surja del uso o incapacidad de usar los
                  materiales en ZapTrack, incluso si ZapTrack o un representante
                  autorizado de ZapTrack ha sido notificado oralmente o por
                  escrito de la posibilidad de tal daño.
                </p>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-2">
                  5. Precisión de los Materiales
                </h2>
                <p>
                  Los materiales que aparecen en ZapTrack podrían incluir
                  errores técnicos, tipográficos o fotográficos. ZapTrack no
                  garantiza que cualquiera de los materiales en su sitio web sea
                  preciso, completo o actual. ZapTrack puede realizar cambios a
                  los materiales contenidos en su sitio web en cualquier momento
                  sin previo aviso.
                </p>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-2">6. Enlaces</h2>
                <p>
                  ZapTrack no ha revisado todos los sitios enlazados a su sitio
                  web y no es responsable por los contenidos de ningún sitio
                  enlazado. La inclusión de cualquier enlace no implica
                  aprobación por parte de ZapTrack del sitio. El uso de
                  cualquier sitio web enlazado es bajo el propio riesgo del
                  usuario.
                </p>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-2">
                  7. Modificaciones
                </h2>
                <p>
                  ZapTrack puede revisar estos términos de servicio para su
                  sitio web en cualquier momento sin previo aviso. Al usar este
                  sitio web, usted acepta estar sujeto a la versión actual de
                  estos términos de servicio.
                </p>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-2">8. Ley Aplicable</h2>
                <p>
                  Estos términos y condiciones se rigen e interpretan de acuerdo
                  con las leyes de Colombia y usted se somete irrevocablemente a
                  la jurisdicción exclusiva de los tribunales en ese estado o
                  localidad.
                </p>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-2">
                  9. Protección de Datos Personales
                </h2>
                <p>
                  ZapTrack se compromete a cumplir con la Ley 1581 de 2012 y el
                  Decreto 1377 de 2013 sobre la protección de datos personales
                  en Colombia. Los usuarios tienen derecho a conocer, actualizar
                  y rectificar sus datos personales, así como a revocar la
                  autorización y/o solicitar la supresión del dato cuando en el
                  tratamiento no se respeten los principios, derechos y
                  garantías constitucionales y legales.
                </p>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-2">
                  10. Resolución de Disputas
                </h2>
                <p>
                  Cualquier controversia o reclamo que surja de o esté
                  relacionado con estos Términos y Condiciones, o el
                  incumplimiento de los mismos, se resolverá mediante arbitraje
                  administrado por la Cámara de Comercio de Bogotá de acuerdo
                  con sus reglas de arbitraje, y el juicio sobre el laudo
                  dictado por el árbitro puede ser presentado en cualquier
                  tribunal que tenga jurisdicción.
                </p>
              </section>
            </div>
          </ScrollArea>
        </CardContent>
      </Card>
    </div>
  );
}
