import { useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Calendar } from "@/components/ui/calendar";
import { CalendarIcon, PlusCircle, FileText } from "lucide-react";
import { format } from "date-fns";
import Narbar from "@/components/narbar/narbar";

export default function Taxes() {
  const [activeTab, setActiveTab] = useState("calculadora");
  const [baseImponible, setBaseImponible] = useState(0);
  const [tipoImpuesto, setTipoImpuesto] = useState("iva");
  const [porcentajeImpuesto, setPorcentajeImpuesto] = useState(19);
  const [fecha, setFecha] = useState<Date | undefined>(new Date());

  const [registros, setRegistros] = useState([
    {
      id: 1,
      fecha: "2023-05-15",
      tipo: "IVA",
      base: 1000000,
      porcentaje: 19,
      monto: 190000,
    },
    {
      id: 2,
      fecha: "2023-06-20",
      tipo: "Retención en la Fuente",
      base: 2000000,
      porcentaje: 4,
      monto: 80000,
    },
    {
      id: 3,
      fecha: "2023-07-10",
      tipo: "ICA",
      base: 1500000,
      porcentaje: 0.966,
      monto: 14490,
    },
  ]);

  const calcularImpuesto = () => {
    return (baseImponible * porcentajeImpuesto) / 100;
  };

  const handleRegistrarImpuesto = () => {
    const nuevoRegistro = {
      id: registros.length + 1,
      fecha: format(fecha || new Date(), "yyyy-MM-dd"),
      tipo: tipoImpuesto.toUpperCase(),
      base: baseImponible,
      porcentaje: porcentajeImpuesto,
      monto: calcularImpuesto(),
    };
    setRegistros([...registros, nuevoRegistro]);
  };

  return (
    <div>
      <Narbar />
      <div className="container mx-auto py-10">
        <h1 className="text-3xl font-bold mb-5">Gestión de Impuestos</h1>
        <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
          <TabsList className="grid w-full grid-cols-3">
            <TabsTrigger value="calculadora">
              Calculadora de Impuestos
            </TabsTrigger>
            <TabsTrigger value="registros">Registros de Impuestos</TabsTrigger>
            <TabsTrigger value="informes">Informes y Declaraciones</TabsTrigger>
          </TabsList>
          <TabsContent value="calculadora">
            <Card>
              <CardHeader>
                <CardTitle>Calculadora de Impuestos</CardTitle>
                <CardDescription>
                  Calcula el monto de impuestos a pagar según el tipo y la base
                  imponible.
                </CardDescription>
              </CardHeader>
              <CardContent className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <Label htmlFor="tipoImpuesto">Tipo de Impuesto</Label>
                    <Select
                      value={tipoImpuesto}
                      onValueChange={setTipoImpuesto}
                    >
                      <SelectTrigger id="tipoImpuesto">
                        <SelectValue placeholder="Seleccionar tipo de impuesto" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="iva">IVA</SelectItem>
                        <SelectItem value="retencion">
                          Retención en la Fuente
                        </SelectItem>
                        <SelectItem value="ica">ICA</SelectItem>
                        <SelectItem value="renta">Impuesto de Renta</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="baseImponible">Base Imponible</Label>
                    <Input
                      id="baseImponible"
                      type="number"
                      value={baseImponible}
                      onChange={(e) => setBaseImponible(Number(e.target.value))}
                      placeholder="Ingrese la base imponible"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <Label htmlFor="porcentajeImpuesto">
                      Porcentaje de Impuesto
                    </Label>
                    <Input
                      id="porcentajeImpuesto"
                      type="number"
                      value={porcentajeImpuesto}
                      onChange={(e) =>
                        setPorcentajeImpuesto(Number(e.target.value))
                      }
                      placeholder="Ingrese el porcentaje"
                    />
                  </div>
                  <div className="space-y-2">
                    <Label>Fecha de Cálculo</Label>
                    <div className="flex items-center">
                      <Input
                        type="text"
                        value={fecha ? format(fecha, "PP") : ""}
                        readOnly
                        className="w-full"
                      />
                      <Dialog>
                        <DialogTrigger asChild>
                          <Button variant="outline" className="ml-2">
                            <CalendarIcon className="h-4 w-4" />
                          </Button>
                        </DialogTrigger>
                        <DialogContent>
                          <Calendar
                            mode="single"
                            selected={fecha}
                            onSelect={setFecha}
                            initialFocus
                          />
                        </DialogContent>
                      </Dialog>
                    </div>
                  </div>
                </div>
                <div className="pt-4">
                  <h3 className="text-lg font-semibold">
                    Resultado del Cálculo:
                  </h3>
                  <p className="text-2xl font-bold">
                    ${calcularImpuesto().toLocaleString()}
                  </p>
                </div>
              </CardContent>
              <CardFooter>
                <Button onClick={handleRegistrarImpuesto}>
                  Registrar Impuesto
                </Button>
              </CardFooter>
            </Card>
          </TabsContent>
          <TabsContent value="registros">
            <Card>
              <CardHeader>
                <CardTitle>Registros de Impuestos</CardTitle>
                <CardDescription>
                  Historial de impuestos calculados y registrados.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Fecha</TableHead>
                      <TableHead>Tipo de Impuesto</TableHead>
                      <TableHead>Base Imponible</TableHead>
                      <TableHead>Porcentaje</TableHead>
                      <TableHead>Monto</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {registros.map((registro) => (
                      <TableRow key={registro.id}>
                        <TableCell>{registro.fecha}</TableCell>
                        <TableCell>{registro.tipo}</TableCell>
                        <TableCell>${registro.base.toLocaleString()}</TableCell>
                        <TableCell>{registro.porcentaje}%</TableCell>
                        <TableCell>
                          ${registro.monto.toLocaleString()}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </TabsContent>
          <TabsContent value="informes">
            <Card>
              <CardHeader>
                <CardTitle>Informes y Declaraciones</CardTitle>
                <CardDescription>
                  Genera informes y prepara declaraciones de impuestos.
                </CardDescription>
              </CardHeader>
              <CardContent className="space-y-4">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  <Card>
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                      <CardTitle className="text-sm font-medium">
                        Declaración de IVA
                      </CardTitle>
                      <FileText className="h-4 w-4 text-muted-foreground" />
                    </CardHeader>
                    <CardContent>
                      <Button className="w-full">Generar Declaración</Button>
                    </CardContent>
                  </Card>
                  <Card>
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                      <CardTitle className="text-sm font-medium">
                        Retención en la Fuente
                      </CardTitle>
                      <FileText className="h-4 w-4 text-muted-foreground" />
                    </CardHeader>
                    <CardContent>
                      <Button className="w-full">Generar Informe</Button>
                    </CardContent>
                  </Card>
                  <Card>
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                      <CardTitle className="text-sm font-medium">
                        Declaración de Renta
                      </CardTitle>
                      <FileText className="h-4 w-4 text-muted-foreground" />
                    </CardHeader>
                    <CardContent>
                      <Button className="w-full">Preparar Declaración</Button>
                    </CardContent>
                  </Card>
                </div>
                <div>
                  <h3 className="text-lg font-semibold mb-2">
                    Calendario Tributario
                  </h3>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Impuesto</TableHead>
                        <TableHead>Frecuencia</TableHead>
                        <TableHead>Próxima Fecha</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      <TableRow>
                        <TableCell>IVA</TableCell>
                        <TableCell>Bimestral</TableCell>
                        <TableCell>2023-08-15</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Retención en la Fuente</TableCell>
                        <TableCell>Mensual</TableCell>
                        <TableCell>2023-07-20</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>ICA</TableCell>
                        <TableCell>Bimestral</TableCell>
                        <TableCell>2023-09-15</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </CardContent>
              <CardFooter>
                <Button>
                  <PlusCircle className="mr-2 h-4 w-4" />
                  Agendar Recordatorio
                </Button>
              </CardFooter>
            </Card>
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
}
