"use client";

import {
  Backpack,
  Users,
  Clock,
  MapIcon as RoadMap,
  ListTodo,
} from "lucide-react";
import { cn } from "@/lib/utils";
import { Card } from "@/components/ui/card";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";

interface ProjectTabsProps {
  activeTab: string;
  onTabChange: (tabId: string) => void;
  className?: string;
}

export default function ProjectTabs({
  activeTab,
  onTabChange,
  className,
}: ProjectTabsProps) {
  const tabs = [
    { id: "backlog", label: "Backlog", icon: Backpack },
    { id: "team", label: "Team capacity", icon: Users },
    { id: "iteration", label: "Current iteration", icon: Clock },
    { id: "roadmap", label: "Roadmap", icon: RoadMap },
    { id: "items", label: "My items", icon: ListTodo },
  ];

  return (
    <Card className={cn("border-b rounded-none", className)}>
      <Tabs value={activeTab} onValueChange={onTabChange} className="w-full">
        <TabsList className="w-full justify-start gap-2 rounded-none border-b bg-transparent p-0">
          {tabs.map((tab) => (
            <TabsTrigger
              key={tab.id}
              value={tab.id}
              className={cn(
                "rounded-none border-b-2 border-transparent pb-2 pt-2 data-[state=active]:border-primary",
                "gap-2 [&>svg]:h-4 [&>svg]:w-4"
              )}
            >
              <tab.icon className="h-4 w-4" />
              {tab.label}
            </TabsTrigger>
          ))}
        </TabsList>
      </Tabs>
    </Card>
  );
}
