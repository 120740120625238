/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dependencies } from "..";

export const build = ({ http, abortController }: Dependencies) => {
  const PATH = "/file-directory";
  const execute = (userID: string) => {
    const controller = abortController();

    return {
      response: http.delete<any>(`${PATH}/empty-trash/${userID}`),
      controller,
    };
  };
  return execute;
};
