import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useStore } from "@/packages/storage/zustand";

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const token = useStore((state) => state.token);
  const user = useStore((state) => state.user);
  const logout = useStore((state) => state.logout);
  const isHydrated = useStore((state) => state._hasHydrated);

  if (!isHydrated) {
    return <div>Loading...</div>;
  }

  if (!token || !user || (user.exp && user.exp * 1000 < Date.now())) {
    logout();
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
