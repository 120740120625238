import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Search, Plus, Edit, Trash2, UserPlus } from "lucide-react";
import { useState } from "react";
import Narbar from "@/components/narbar/narbar";

type JobOpening = {
  id: number;
  title: string;
  department: string;
  location: string;
  status: "open" | "closed";
  description: string;
};

type Candidate = {
  id: number;
  name: string;
  email: string;
  phone: string;
  position: string;
  status: "new" | "screening" | "interview" | "offer" | "hired" | "rejected";
};

const initialJobOpenings: JobOpening[] = [
  {
    id: 1,
    title: "Software Developer",
    department: "IT",
    location: "Remote",
    status: "open",
    description: "We are looking for a skilled software developer...",
  },
  {
    id: 2,
    title: "Marketing Specialist",
    department: "Marketing",
    location: "New York",
    status: "open",
    description: "Join our dynamic marketing team...",
  },
];

const initialCandidates: Candidate[] = [
  {
    id: 1,
    name: "Alice Johnson",
    email: "alice@example.com",
    phone: "123-456-7890",
    position: "Software Developer",
    status: "screening",
  },
  {
    id: 2,
    name: "Bob Williams",
    email: "bob@example.com",
    phone: "098-765-4321",
    position: "Marketing Specialist",
    status: "interview",
  },
];

export default function Recruitment() {
  const [jobOpenings, setJobOpenings] =
    useState<JobOpening[]>(initialJobOpenings);
  const [candidates, setCandidates] = useState<Candidate[]>(initialCandidates);
  const [searchTerm, setSearchTerm] = useState("");
  const [newJobOpening, setNewJobOpening] = useState<Omit<JobOpening, "id">>({
    title: "",
    department: "",
    location: "",
    status: "open",
    description: "",
  });
  const [newCandidate, setNewCandidate] = useState<Omit<Candidate, "id">>({
    name: "",
    email: "",
    phone: "",
    position: "",
    status: "new",
  });
  const [editingJobOpening, setEditingJobOpening] = useState<JobOpening | null>(
    null
  );
  const [editingCandidate, setEditingCandidate] = useState<Candidate | null>(
    null
  );

  const filteredJobOpenings = jobOpenings.filter(
    (job) =>
      job.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      job.department.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredCandidates = candidates.filter(
    (candidate) =>
      candidate.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      candidate.position.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAddJobOpening = () => {
    setJobOpenings([
      ...jobOpenings,
      { ...newJobOpening, id: jobOpenings.length + 1 },
    ]);
    setNewJobOpening({
      title: "",
      department: "",
      location: "",
      status: "open",
      description: "",
    });
  };

  const handleEditJobOpening = () => {
    if (editingJobOpening) {
      setJobOpenings(
        jobOpenings.map((job) =>
          job.id === editingJobOpening.id ? editingJobOpening : job
        )
      );
      setEditingJobOpening(null);
    }
  };

  const handleDeleteJobOpening = (id: number) => {
    setJobOpenings(jobOpenings.filter((job) => job.id !== id));
  };

  const handleAddCandidate = () => {
    setCandidates([
      ...candidates,
      { ...newCandidate, id: candidates.length + 1 },
    ]);
    setNewCandidate({
      name: "",
      email: "",
      phone: "",
      position: "",
      status: "new",
    });
  };

  const handleEditCandidate = () => {
    if (editingCandidate) {
      setCandidates(
        candidates.map((candidate) =>
          candidate.id === editingCandidate.id ? editingCandidate : candidate
        )
      );
      setEditingCandidate(null);
    }
  };

  const handleDeleteCandidate = (id: number) => {
    setCandidates(candidates.filter((candidate) => candidate.id !== id));
  };

  return (
    <div>
      <Narbar />
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">Reclutamiento</h1>

        <div className="flex justify-between items-center mb-4">
          <div className="relative w-64">
            <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-500" />
            <Input
              type="text"
              placeholder="Buscar..."
              className="pl-8"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        <Tabs defaultValue="job-openings" className="space-y-4">
          <TabsList>
            <TabsTrigger value="job-openings">Vacantes</TabsTrigger>
            <TabsTrigger value="candidates">Candidatos</TabsTrigger>
          </TabsList>

          <TabsContent value="job-openings">
            <Card>
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle>Vacantes</CardTitle>
                <Dialog>
                  <DialogTrigger asChild>
                    <Button>
                      <Plus className="mr-2 h-4 w-4" /> Nueva Vacante
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>Agregar Nueva Vacante</DialogTitle>
                    </DialogHeader>
                    <div className="grid gap-4 py-4">
                      <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="title" className="text-right">
                          Título
                        </Label>
                        <Input
                          id="title"
                          className="col-span-3"
                          value={newJobOpening.title}
                          onChange={(e) =>
                            setNewJobOpening({
                              ...newJobOpening,
                              title: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="department" className="text-right">
                          Departamento
                        </Label>
                        <Input
                          id="department"
                          className="col-span-3"
                          value={newJobOpening.department}
                          onChange={(e) =>
                            setNewJobOpening({
                              ...newJobOpening,
                              department: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="location" className="text-right">
                          Ubicación
                        </Label>
                        <Input
                          id="location"
                          className="col-span-3"
                          value={newJobOpening.location}
                          onChange={(e) =>
                            setNewJobOpening({
                              ...newJobOpening,
                              location: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="description" className="text-right">
                          Descripción
                        </Label>
                        <Textarea
                          id="description"
                          className="col-span-3"
                          value={newJobOpening.description}
                          onChange={(e) =>
                            setNewJobOpening({
                              ...newJobOpening,
                              description: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <Button onClick={handleAddJobOpening}>
                      Agregar Vacante
                    </Button>
                  </DialogContent>
                </Dialog>
              </CardHeader>
              <CardContent>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Título</TableHead>
                      <TableHead>Departamento</TableHead>
                      <TableHead>Ubicación</TableHead>
                      <TableHead>Estado</TableHead>
                      <TableHead>Acciones</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {filteredJobOpenings.map((job) => (
                      <TableRow key={job.id}>
                        <TableCell className="font-medium">
                          {job.title}
                        </TableCell>
                        <TableCell>{job.department}</TableCell>
                        <TableCell>{job.location}</TableCell>
                        <TableCell>
                          <Badge
                            variant={
                              job.status === "open" ? "default" : "secondary"
                            }
                          >
                            {job.status === "open" ? "Abierta" : "Cerrada"}
                          </Badge>
                        </TableCell>
                        <TableCell>
                          <div className="flex space-x-2">
                            <Dialog>
                              <DialogTrigger asChild>
                                <Button
                                  variant="outline"
                                  size="icon"
                                  onClick={() => setEditingJobOpening(job)}
                                >
                                  <Edit className="h-4 w-4" />
                                </Button>
                              </DialogTrigger>
                              <DialogContent>
                                <DialogHeader>
                                  <DialogTitle>Editar Vacante</DialogTitle>
                                </DialogHeader>
                                {editingJobOpening && (
                                  <div className="grid gap-4 py-4">
                                    <div className="grid grid-cols-4 items-center gap-4">
                                      <Label
                                        htmlFor="edit-title"
                                        className="text-right"
                                      >
                                        Título
                                      </Label>
                                      <Input
                                        id="edit-title"
                                        className="col-span-3"
                                        value={editingJobOpening.title}
                                        onChange={(e) =>
                                          setEditingJobOpening({
                                            ...editingJobOpening,
                                            title: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                    <div className="grid grid-cols-4 items-center gap-4">
                                      <Label
                                        htmlFor="edit-department"
                                        className="text-right"
                                      >
                                        Departamento
                                      </Label>
                                      <Input
                                        id="edit-department"
                                        className="col-span-3"
                                        value={editingJobOpening.department}
                                        onChange={(e) =>
                                          setEditingJobOpening({
                                            ...editingJobOpening,
                                            department: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                    <div className="grid grid-cols-4 items-center gap-4">
                                      <Label
                                        htmlFor="edit-location"
                                        className="text-right"
                                      >
                                        Ubicación
                                      </Label>
                                      <Input
                                        id="edit-location"
                                        className="col-span-3"
                                        value={editingJobOpening.location}
                                        onChange={(e) =>
                                          setEditingJobOpening({
                                            ...editingJobOpening,
                                            location: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                    <div className="grid grid-cols-4 items-center gap-4">
                                      <Label
                                        htmlFor="edit-status"
                                        className="text-right"
                                      >
                                        Estado
                                      </Label>
                                      <Select
                                        onValueChange={(value) =>
                                          setEditingJobOpening({
                                            ...editingJobOpening,
                                            status: value as "open" | "closed",
                                          })
                                        }
                                      >
                                        <SelectTrigger className="col-span-3">
                                          <SelectValue
                                            placeholder={
                                              editingJobOpening.status
                                            }
                                          />
                                        </SelectTrigger>
                                        <SelectContent>
                                          <SelectItem value="open">
                                            Abierta
                                          </SelectItem>
                                          <SelectItem value="closed">
                                            Cerrada
                                          </SelectItem>
                                        </SelectContent>
                                      </Select>
                                    </div>
                                    <div className="grid grid-cols-4 items-center gap-4">
                                      <Label
                                        htmlFor="edit-description"
                                        className="text-right"
                                      >
                                        Descripción
                                      </Label>
                                      <Textarea
                                        id="edit-description"
                                        className="col-span-3"
                                        value={editingJobOpening.description}
                                        onChange={(e) =>
                                          setEditingJobOpening({
                                            ...editingJobOpening,
                                            description: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                  </div>
                                )}
                                <Button onClick={handleEditJobOpening}>
                                  Guardar Cambios
                                </Button>
                              </DialogContent>
                            </Dialog>
                            <Button
                              variant="outline"
                              size="icon"
                              onClick={() => handleDeleteJobOpening(job.id)}
                            >
                              <Trash2 className="h-4 w-4" />
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </TabsContent>

          <TabsContent value="candidates">
            <Card>
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle>Candidatos</CardTitle>
                <Dialog>
                  <DialogTrigger asChild>
                    <Button>
                      <UserPlus className="mr-2 h-4 w-4" /> Nuevo Candidato
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>Agregar Nuevo Candidato</DialogTitle>
                    </DialogHeader>
                    <div className="grid gap-4 py-4">
                      <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="name" className="text-right">
                          Nombre
                        </Label>
                        <Input
                          id="name"
                          className="col-span-3"
                          value={newCandidate.name}
                          onChange={(e) =>
                            setNewCandidate({
                              ...newCandidate,
                              name: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="email" className="text-right">
                          Email
                        </Label>
                        <Input
                          id="email"
                          className="col-span-3"
                          value={newCandidate.email}
                          onChange={(e) =>
                            setNewCandidate({
                              ...newCandidate,
                              email: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="phone" className="text-right">
                          Teléfono
                        </Label>
                        <Input
                          id="phone"
                          className="col-span-3"
                          value={newCandidate.phone}
                          onChange={(e) =>
                            setNewCandidate({
                              ...newCandidate,
                              phone: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="position" className="text-right">
                          Posición
                        </Label>
                        <Input
                          id="position"
                          className="col-span-3"
                          value={newCandidate.position}
                          onChange={(e) =>
                            setNewCandidate({
                              ...newCandidate,
                              position: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <Button onClick={handleAddCandidate}>
                      Agregar Candidato
                    </Button>
                  </DialogContent>
                </Dialog>
              </CardHeader>
              <CardContent>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Nombre</TableHead>
                      <TableHead>Email</TableHead>
                      <TableHead>Teléfono</TableHead>
                      <TableHead>Posición</TableHead>
                      <TableHead>Estado</TableHead>
                      <TableHead>Acciones</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {filteredCandidates.map((candidate) => (
                      <TableRow key={candidate.id}>
                        <TableCell className="font-medium">
                          {candidate.name}
                        </TableCell>
                        <TableCell>{candidate.email}</TableCell>
                        <TableCell>{candidate.phone}</TableCell>
                        <TableCell>{candidate.position}</TableCell>
                        <TableCell>
                          <Badge variant="outline">{candidate.status}</Badge>
                        </TableCell>
                        <TableCell>
                          <div className="flex space-x-2">
                            <Dialog>
                              <DialogTrigger asChild>
                                <Button
                                  variant="outline"
                                  size="icon"
                                  onClick={() => setEditingCandidate(candidate)}
                                >
                                  <Edit className="h-4 w-4" />
                                </Button>
                              </DialogTrigger>
                              <DialogContent>
                                <DialogHeader>
                                  <DialogTitle>Editar Candidato</DialogTitle>
                                </DialogHeader>
                                {editingCandidate && (
                                  <div className="grid gap-4 py-4">
                                    <div className="grid grid-cols-4 items-center gap-4">
                                      <Label
                                        htmlFor="edit-name"
                                        className="text-right"
                                      >
                                        Nombre
                                      </Label>
                                      <Input
                                        id="edit-name"
                                        className="col-span-3"
                                        value={editingCandidate.name}
                                        onChange={(e) =>
                                          setEditingCandidate({
                                            ...editingCandidate,
                                            name: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                    <div className="grid grid-cols-4 items-center gap-4">
                                      <Label
                                        htmlFor="edit-email"
                                        className="text-right"
                                      >
                                        Email
                                      </Label>
                                      <Input
                                        id="edit-email"
                                        className="col-span-3"
                                        value={editingCandidate.email}
                                        onChange={(e) =>
                                          setEditingCandidate({
                                            ...editingCandidate,
                                            email: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                    <div className="grid grid-cols-4 items-center gap-4">
                                      <Label
                                        htmlFor="edit-phone"
                                        className="text-right"
                                      >
                                        Teléfono
                                      </Label>
                                      <Input
                                        id="edit-phone"
                                        className="col-span-3"
                                        value={editingCandidate.phone}
                                        onChange={(e) =>
                                          setEditingCandidate({
                                            ...editingCandidate,
                                            phone: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                    <div className="grid grid-cols-4 items-center gap-4">
                                      <Label
                                        htmlFor="edit-position"
                                        className="text-right"
                                      >
                                        Posición
                                      </Label>
                                      <Input
                                        id="edit-position"
                                        className="col-span-3"
                                        value={editingCandidate.position}
                                        onChange={(e) =>
                                          setEditingCandidate({
                                            ...editingCandidate,
                                            position: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                    <div className="grid grid-cols-4 items-center gap-4">
                                      <Label
                                        htmlFor="edit-status"
                                        className="text-right"
                                      >
                                        Estado
                                      </Label>
                                      <Select
                                        onValueChange={(value) =>
                                          setEditingCandidate({
                                            ...editingCandidate,
                                            status:
                                              value as Candidate["status"],
                                          })
                                        }
                                      >
                                        <SelectTrigger className="col-span-3">
                                          <SelectValue
                                            placeholder={
                                              editingCandidate.status
                                            }
                                          />
                                        </SelectTrigger>
                                        <SelectContent>
                                          <SelectItem value="new">
                                            Nuevo
                                          </SelectItem>
                                          <SelectItem value="screening">
                                            Evaluación
                                          </SelectItem>
                                          <SelectItem value="interview">
                                            Entrevista
                                          </SelectItem>
                                          <SelectItem value="offer">
                                            Oferta
                                          </SelectItem>
                                          <SelectItem value="hired">
                                            Contratado
                                          </SelectItem>
                                          <SelectItem value="rejected">
                                            Rechazado
                                          </SelectItem>
                                        </SelectContent>
                                      </Select>
                                    </div>
                                  </div>
                                )}
                                <Button onClick={handleEditCandidate}>
                                  Guardar Cambios
                                </Button>
                              </DialogContent>
                            </Dialog>
                            <Button
                              variant="outline"
                              size="icon"
                              onClick={() =>
                                handleDeleteCandidate(candidate.id)
                              }
                            >
                              <Trash2 className="h-4 w-4" />
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
}
