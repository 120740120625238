"use client";

import { useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import Narbar from "@/components/narbar/narbar";

type Producto = {
  nombre: string;
  cantidad: number;
  precioUnitario: number;
  iva: number;
};

type ProductoKey = keyof Producto;

export default function PurchaseAndSales() {
  const [activeTab, setActiveTab] = useState("ventas");
  const [productos, setProductos] = useState<Producto[]>([
    { nombre: "", cantidad: 0, precioUnitario: 0, iva: 19 },
  ]);

  const addProducto = () => {
    setProductos([
      ...productos,
      { nombre: "", cantidad: 0, precioUnitario: 0, iva: 19 },
    ]);
  };

  const updateProducto = (
    index: number,
    field: ProductoKey,
    value: string | number
  ) => {
    const newProductos = [...productos];
    if (field === "nombre") {
      newProductos[index][field] = value as string;
    } else {
      newProductos[index][field] = Number(value);
    }
    setProductos(newProductos);
  };

  const calcularSubtotal = () => {
    return productos.reduce(
      (total, producto) => total + producto.cantidad * producto.precioUnitario,
      0
    );
  };

  const calcularIVA = () => {
    return productos.reduce(
      (total, producto) =>
        total +
        producto.cantidad * producto.precioUnitario * (producto.iva / 100),
      0
    );
  };

  const calcularTotal = () => {
    return calcularSubtotal() + calcularIVA();
  };

  return (
    <div>
      <Narbar />
      <div className="container mx-auto py-10">
        <h1 className="text-2xl font-bold mb-5">Facturación</h1>
        <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="ventas">Ventas</TabsTrigger>
            <TabsTrigger value="compras">Compras</TabsTrigger>
          </TabsList>
          <TabsContent value="ventas">
            <Card>
              <CardHeader>
                <CardTitle>Factura de Venta</CardTitle>
                <CardDescription>
                  Genera facturas de venta conforme a la normativa colombiana.
                </CardDescription>
              </CardHeader>
              <CardContent className="space-y-4">
                <div className="grid grid-cols-3 gap-4">
                  <div className="space-y-2">
                    <Label htmlFor="tipoFactura">Tipo de Factura</Label>
                    <Select>
                      <SelectTrigger id="tipoFactura">
                        <SelectValue placeholder="Seleccionar tipo" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="electronica">
                          Factura Electrónica
                        </SelectItem>
                        <SelectItem value="pos">Factura POS</SelectItem>
                        <SelectItem value="computador">
                          Factura de Computador
                        </SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="numeroFactura">Número de Factura</Label>
                    <Input id="numeroFactura" placeholder="FV-001" />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="fecha">Fecha de Emisión</Label>
                    <Input id="fecha" type="date" />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <Label htmlFor="cliente">Cliente</Label>
                    <Input id="cliente" placeholder="Nombre o Razón Social" />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="nit">NIT / Cédula</Label>
                    <Input id="nit" placeholder="NIT o Cédula del cliente" />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <Label htmlFor="direccion">Dirección</Label>
                    <Input id="direccion" placeholder="Dirección del cliente" />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="telefono">Teléfono</Label>
                    <Input id="telefono" placeholder="Teléfono del cliente" />
                  </div>
                </div>
                <div className="space-y-2">
                  <Label>Productos</Label>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Producto</TableHead>
                        <TableHead>Cantidad</TableHead>
                        <TableHead>Precio Unitario</TableHead>
                        <TableHead>IVA %</TableHead>
                        <TableHead>Subtotal</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {productos.map((producto, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Input
                              placeholder="Nombre del producto"
                              value={producto.nombre}
                              onChange={(e) =>
                                updateProducto(index, "nombre", e.target.value)
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <Input
                              type="number"
                              placeholder="Cantidad"
                              value={producto.cantidad}
                              onChange={(e) =>
                                updateProducto(
                                  index,
                                  "cantidad",
                                  Number(e.target.value)
                                )
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <Input
                              type="number"
                              placeholder="Precio"
                              value={producto.precioUnitario}
                              onChange={(e) =>
                                updateProducto(
                                  index,
                                  "precioUnitario",
                                  Number(e.target.value)
                                )
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <Select
                              value={producto.iva.toString()}
                              onValueChange={(value) =>
                                updateProducto(index, "iva", Number(value))
                              }
                            >
                              <SelectTrigger>
                                <SelectValue />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="0">0%</SelectItem>
                                <SelectItem value="5">5%</SelectItem>
                                <SelectItem value="19">19%</SelectItem>
                              </SelectContent>
                            </Select>
                          </TableCell>
                          <TableCell>
                            {(
                              producto.cantidad * producto.precioUnitario
                            ).toFixed(2)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Button variant="outline" size="sm" onClick={addProducto}>
                    Añadir Producto
                  </Button>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <Label htmlFor="formaPago">Forma de Pago</Label>
                    <Select>
                      <SelectTrigger id="formaPago">
                        <SelectValue placeholder="Seleccionar forma de pago" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="efectivo">Efectivo</SelectItem>
                        <SelectItem value="tarjeta">
                          Tarjeta de Crédito/Débito
                        </SelectItem>
                        <SelectItem value="transferencia">
                          Transferencia Bancaria
                        </SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                  <div>
                    <Label htmlFor="metodoPago">Método de Pago</Label>
                    <Select>
                      <SelectTrigger id="metodoPago">
                        <SelectValue placeholder="Seleccionar método de pago" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="contado">Contado</SelectItem>
                        <SelectItem value="credito">Crédito</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                </div>
                <div className="space-y-2">
                  <Label htmlFor="observaciones">Observaciones</Label>
                  <Input
                    id="observaciones"
                    placeholder="Observaciones o notas adicionales"
                  />
                </div>
                <div className="flex justify-end space-x-4">
                  <div className="text-right">
                    <p>Subtotal: ${calcularSubtotal().toFixed(2)}</p>
                    <p>IVA: ${calcularIVA().toFixed(2)}</p>
                    <p className="font-bold">
                      Total: ${calcularTotal().toFixed(2)}
                    </p>
                  </div>
                </div>
              </CardContent>
              <CardFooter>
                <Button>Generar Factura</Button>
              </CardFooter>
            </Card>
          </TabsContent>
          <TabsContent value="compras">
            {/* Implementar un formulario similar para las compras */}
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
}
