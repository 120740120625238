import {
  File,
  FileText,
  FileSpreadsheet,
  FileImage,
  FileAudio,
  FileVideo,
} from "lucide-react";

export const getFileIcon = (fileName: string, isDeleted: boolean = false) => {
  const extension = fileName.split(".").pop()?.toLowerCase();
  const iconClass = isDeleted ? "text-gray-300" : "";

  switch (extension) {
    case "doc":
    case "docx":
      return <File className={`h-10 w-10 text-blue-500 ${iconClass}`} />;
    case "xls":
    case "xlsx":
      return (
        <FileSpreadsheet className={`h-10 w-10 text-green-500 ${iconClass}`} />
      );
    case "ppt":
    case "pptx":
      return <File className={`h-10 w-10 text-orange-500 ${iconClass}`} />;
    case "pdf":
      return <File className={`h-10 w-10 text-red-500 ${iconClass}`} />;
    case "txt":
      return <FileText className={`h-10 w-10 text-gray-500 ${iconClass}`} />;
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
    case "bmp":
      return <FileImage className={`h-10 w-10 text-purple-500 ${iconClass}`} />;
    case "mp3":
    case "wav":
    case "ogg":
      return <FileAudio className={`h-10 w-10 text-yellow-500 ${iconClass}`} />;
    case "mp4":
    case "avi":
    case "mov":
      return <FileVideo className={`h-10 w-10 text-pink-500 ${iconClass}`} />;
    default:
      return <File className={`h-10 w-10 text-gray-500 ${iconClass}`} />;
  }
};
