import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

interface User {
  _id: string;
  Name: string;
  email: string;
  BirthDate: string;
  DocumentNumber: string;
  Role: string;
  Position: string;
  iat: number;
  exp: number;
}

interface AuthState {
  user: User | null;
  token: string | null;
  _hasHydrated: boolean;
  setUser: (user: User) => void;
  setToken: (token: string) => void;
  setHydrated: (state: boolean) => void;
  logout: () => void;
}

export const useStore = create<AuthState>()(
  persist(
    (set) => ({
      user: null,
      token: null,
      _hasHydrated: false,

      setHydrated: (state) => {
        set({
          _hasHydrated: state,
        });
      },

      setUser: (user) => set({ user }),
      setToken: (token) => set({ token }),
      logout: () => {
        set({ user: null, token: null });
      },
    }),
    {
      name: "auth-storage",
      storage: createJSONStorage(() => localStorage),
      onRehydrateStorage: () => (state) => {
        state?.setHydrated(true);
      },
      partialize: (state) => ({
        user: state.user,
        token: state.token,
      }),
    }
  )
);
