import React, { useState } from "react";
import { X, Upload } from "lucide-react";
import { Progress } from "@/components/ui/progress";

interface FloatingUploadProgressProps {
  uploadProgress: Record<string, number>;
}

const FloatingUploadProgress: React.FC<FloatingUploadProgressProps> = ({
  uploadProgress,
}) => {
  const [isExpanded, setIsExpanded] = useState(true);

  if (Object.keys(uploadProgress).length === 0) return null;

  const totalProgress =
    Object.values(uploadProgress).reduce((sum, progress) => sum + progress, 0) /
    Object.keys(uploadProgress).length;

  if (!isExpanded) {
    return (
      <div
        className="fixed bottom-4 right-4 bg-blue-500 text-white rounded-full p-3 cursor-pointer shadow-lg"
        onClick={() => setIsExpanded(true)}
      >
        <Upload className="h-6 w-6" />
      </div>
    );
  }

  return (
    <div className="fixed bottom-4 right-4 bg-white p-4 rounded-lg shadow-lg max-w-sm w-full">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold">Subiendo archivos</h3>
        <button
          onClick={() => setIsExpanded(false)}
          className="text-gray-500 hover:text-gray-700"
          aria-label="Minimizar"
        >
          <X className="h-5 w-5" />
        </button>
      </div>
      {Object.entries(uploadProgress).map(([fileId, progress]) => (
        <div key={fileId} className="mb-4 last:mb-0">
          <div className="flex justify-between items-center mb-1">
            <span className="text-sm font-medium text-gray-700 truncate max-w-[200px]">
              {fileId}
            </span>
            <span className="text-sm font-medium text-gray-700">
              {progress.toFixed(0)}%
            </span>
          </div>
          <Progress value={progress} className="w-full" />
        </div>
      ))}
      <div className="mt-4">
        <div className="flex justify-between items-center mb-1">
          <span className="text-sm font-medium text-gray-700">
            Progreso total
          </span>
          <span className="text-sm font-medium text-gray-700">
            {totalProgress.toFixed(0)}%
          </span>
        </div>
        <Progress value={totalProgress} className="w-full" />
      </div>
    </div>
  );
};

export default FloatingUploadProgress;
