import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import {
  Users,
  UserPlus,
  GraduationCap,
  ClipboardList,
  BarChart,
  Calendar,
  FileText,
  Shield,
  ArrowRight,
} from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import Navbar from "@/components/narbar/narbar";

export default function HRModule() {
  const hrModules = [
    {
      title: "Gestión de Personal",
      description: "Administra información de empleados y contratos",
      icon: Users,
      path: "/rrhh/personal",
      color: "bg-blue-500",
    },
    {
      title: "Reclutamiento",
      description: "Gestiona procesos de selección y contratación",
      icon: UserPlus,
      path: "/rrhh/reclutamiento",
      color: "bg-green-500",
    },
    {
      title: "Capacitación",
      description: "Planifica y administra programas de formación",
      icon: GraduationCap,
      path: "/rrhh/capacitacion",
      color: "bg-yellow-500",
    },
    {
      title: "Evaluación de Desempeño",
      description: "Realiza y analiza evaluaciones de empleados",
      icon: ClipboardList,
      path: "/rrhh/evaluacion",
      color: "bg-red-500",
    },
    {
      title: "Análisis de Datos HR",
      description: "Visualiza métricas y tendencias de RRHH",
      icon: BarChart,
      path: "/rrhh/analisis",
      color: "bg-purple-500",
    },
    {
      title: "Gestión de Tiempo",
      description: "Administra horarios, vacaciones y ausencias",
      icon: Calendar,
      path: "/rrhh/tiempo",
      color: "bg-indigo-500",
    },
    {
      title: "Nómina y Compensaciones",
      description: "Gestiona salarios, bonificaciones y beneficios",
      icon: FileText,
      path: "/rrhh/nomina",
      color: "bg-orange-500",
    },
    {
      title: "Salud y Seguridad Laboral",
      description: "Gestiona programas de bienestar y seguridad",
      icon: Shield,
      path: "/rrhh/seguridad",
      color: "bg-teal-500",
    },
  ];

  return (
    <>
      <Navbar />
      <div className="min-h-screen bg-gradient-to-br from-gray-100 to-gray-200 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-center mb-12"
          >
            <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              Módulo de Recursos Humanos
            </h1>
          </motion.div>

          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {hrModules.map((module, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
              >
                <Card className="h-full transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg">
                  <CardHeader>
                    <div
                      className={`w-16 h-16 ${module.color} rounded-full flex items-center justify-center mb-4`}
                    >
                      <module.icon className="h-8 w-8 text-white" />
                    </div>
                    <CardTitle className="text-2xl font-bold">
                      {module.title}
                    </CardTitle>
                    <CardDescription className="text-gray-600">
                      {module.description}
                    </CardDescription>
                  </CardHeader>
                  <CardContent>
                    <Button asChild className="w-full group" variant="outline">
                      <Link
                        to={module.path}
                        className="flex items-center justify-center gap-2"
                      >
                        Ir al módulo
                        <ArrowRight className="h-4 w-4 transition-transform duration-300 ease-in-out group-hover:translate-x-1" />
                      </Link>
                    </Button>
                  </CardContent>
                </Card>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
