import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Trash2, RefreshCw } from "lucide-react";

interface TrashDialogProps {
  isOpen: boolean;
  onClose: () => void;
  trashFiles: Array<{
    file_name: string;
    deleted_at: string;
  }>;
  onRestore: (fileName: string) => void;
  onEmptyTrash: () => void;
}

export default function TrashDialog({
  isOpen,
  onClose,
  trashFiles,
  onRestore,
  onEmptyTrash,
}: TrashDialogProps) {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="text-xl font-bold flex items-center">
            <Trash2 className="mr-2 h-5 w-5" />
            Papelera
          </DialogTitle>
        </DialogHeader>
        <ScrollArea className="mt-4 max-h-[300px]">
          {trashFiles.length === 0 ? (
            <p className="text-center text-gray-500">Papelera esta vacia</p>
          ) : (
            <ul className="space-y-2">
              {trashFiles.map((file) => (
                <li
                  key={file.file_name}
                  className="flex justify-between items-center"
                >
                  <div>
                    <p className="font-medium">{file.file_name}</p>
                    <p className="text-sm text-gray-500">
                      Deleted on: {new Date(file.deleted_at).toLocaleString()}
                    </p>
                  </div>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => onRestore(file.file_name)}
                  >
                    <RefreshCw className="h-4 w-4 mr-1" />
                    Restaurar
                  </Button>
                </li>
              ))}
            </ul>
          )}
        </ScrollArea>
        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Cerrar
          </Button>
          {trashFiles.length > 0 && (
            <Button variant="destructive" onClick={onEmptyTrash}>
              Vaciar la Papelera
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
