/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useRef } from "react";
import { Droppable, Draggable } from "@hello-pangea/dnd";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Card, CardContent } from "@/components/ui/card";
import { Plus, MoreVertical, Pencil, X } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import { Board, Column, Task } from "../types";
import TaskEditDialog from "./TaskEditDialog";

interface BoardContentProps {
  activeBoard: Board;
  setActiveBoard: (board: Board) => void;
}

export default function BoardContent({
  activeBoard,
  setActiveBoard,
}: BoardContentProps) {
  const [newTaskContent, setNewTaskContent] = useState<string>("");
  const [addingToColumn, setAddingToColumn] = useState<string | null>(null);
  const [editingColumnId, setEditingColumnId] = useState<string | null>(null);
  const [newColumnTitle, setNewColumnTitle] = useState<string>("");
  const editInputRef = useRef<HTMLInputElement>(null);

  const addNewTask = (columnId: string) => {
    if (newTaskContent.trim() === "") return;

    const newTask: Task = {
      id: `task-${Date.now()}`,
      content: newTaskContent,
      startDate: new Date().toISOString().split("T")[0],
      endDate: new Date().toISOString().split("T")[0],
      description: "",
      assignee: "",
      color: "#ffffff",
      tags: [],
    };

    setActiveBoard((prevBoard) => ({
      ...prevBoard,
      columns: {
        ...prevBoard.columns,
        [columnId]: {
          ...prevBoard.columns[columnId],
          tasks: [...prevBoard.columns[columnId].tasks, newTask],
        },
      },
    }));

    setNewTaskContent("");
    setAddingToColumn(null);
  };

  const addNewColumn = () => {
    const newColumnId = `column-${Date.now()}`;
    const newColumn: Column = {
      id: newColumnId,
      title: "Nueva columna",
      tasks: [],
    };

    setActiveBoard((prevBoard) => ({
      ...prevBoard,
      columns: {
        ...prevBoard.columns,
        [newColumnId]: newColumn,
      },
      columnOrder: [...prevBoard.columnOrder, newColumnId],
    }));
  };

  const startEditingColumn = (columnId: string) => {
    setEditingColumnId(columnId);
    setNewColumnTitle(activeBoard.columns[columnId].title);
  };

  const saveColumnTitle = () => {
    if (editingColumnId && newColumnTitle.trim() !== "") {
      setActiveBoard((prevBoard) => ({
        ...prevBoard,
        columns: {
          ...prevBoard.columns,
          [editingColumnId]: {
            ...prevBoard.columns[editingColumnId],
            title: newColumnTitle,
          },
        },
      }));
      setEditingColumnId(null);
    }
  };

  const deleteColumn = (columnId: string) => {
    setActiveBoard((prevBoard: any) => {
      const newColumns = { ...prevBoard.columns };
      delete newColumns[columnId];
      return {
        ...prevBoard,
        columns: newColumns,
        columnOrder: prevBoard.columnOrder.filter(
          (id: string) => id !== columnId
        ),
      };
    });
  };

  const deleteTask = (columnId: string, taskId: string) => {
    setActiveBoard((prevBoard) => ({
      ...prevBoard,
      columns: {
        ...prevBoard.columns,
        [columnId]: {
          ...prevBoard.columns[columnId],
          tasks: prevBoard.columns[columnId].tasks.filter(
            (task: any) => task.id !== taskId
          ),
        },
      },
    }));
  };

  const updateTask = (updatedTask: Task) => {
    setActiveBoard((prevBoard: any) => {
      const updatedColumns = { ...prevBoard.columns };
      for (const columnId in updatedColumns) {
        updatedColumns[columnId] = {
          ...updatedColumns[columnId],
          tasks: updatedColumns[columnId].tasks.map((task: any) =>
            task.id === updatedTask.id ? updatedTask : task
          ),
        };
      }
      return { ...prevBoard, columns: updatedColumns };
    });
  };

  return (
    <main className="flex-1 p-6 overflow-x-auto">
      <h1 className="text-3xl font-bold mb-6">{activeBoard.title}</h1>
      <Droppable droppableId="all-columns" direction="horizontal" type="column">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="flex space-x-4"
          >
            {activeBoard.columnOrder.map((columnId, index) => {
              const column = activeBoard.columns[columnId];
              return (
                <Draggable
                  key={column.id}
                  draggableId={column.id}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className="w-80 flex-shrink-0"
                    >
                      <div className="bg-gray-100 p-4 rounded-lg shadow">
                        <div
                          {...provided.dragHandleProps}
                          className="flex justify-between items-center mb-4"
                        >
                          {editingColumnId === column.id ? (
                            <Input
                              ref={editInputRef}
                              type="text"
                              value={newColumnTitle}
                              onChange={(e) =>
                                setNewColumnTitle(e.target.value)
                              }
                              onBlur={saveColumnTitle}
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  saveColumnTitle();
                                }
                              }}
                              className="font-semibold text-lg"
                            />
                          ) : (
                            <h2 className="font-semibold text-lg">
                              {column.title}
                            </h2>
                          )}
                          <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                              <Button variant="ghost" size="sm">
                                <MoreVertical className="h-4 w-4" />
                              </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent>
                              <DropdownMenuItem
                                onSelect={() => startEditingColumn(column.id)}
                              >
                                <Pencil className="mr-2 h-4 w-4" />
                                Editar título
                              </DropdownMenuItem>
                              <DropdownMenuItem
                                onSelect={() => deleteColumn(column.id)}
                              >
                                <X className="mr-2 h-4 w-4" />
                                Eliminar columna
                              </DropdownMenuItem>
                            </DropdownMenuContent>
                          </DropdownMenu>
                        </div>
                        <Droppable droppableId={column.id} type="task">
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              className="min-h-[200px]"
                            >
                              {column.tasks.map((task, index) => (
                                <Draggable
                                  key={task.id}
                                  draggableId={task.id}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <Card
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className={`mb-3 ${
                                        snapshot.isDragging ? "shadow-lg" : ""
                                      }`}
                                      style={{
                                        ...provided.draggableProps.style,
                                        backgroundColor: task.color,
                                      }}
                                    >
                                      <CardContent className="p-3">
                                        <div className="flex justify-between items-start">
                                          <div>
                                            <p className="text-sm font-medium mb-1">
                                              {task.content}
                                            </p>
                                            <div className="flex flex-wrap gap-1">
                                              {task.tags.map((tag) => (
                                                <span
                                                  key={tag.id}
                                                  className="inline-block px-2 py-1 text-xs font-semibold rounded-full"
                                                  style={{
                                                    backgroundColor: tag.color,
                                                    color: "#ffffff",
                                                  }}
                                                >
                                                  {tag.name}
                                                </span>
                                              ))}
                                            </div>
                                          </div>
                                          <TaskEditDialog
                                            task={task}
                                            onUpdateTask={updateTask}
                                            onDeleteTask={() =>
                                              deleteTask(column.id, task.id)
                                            }
                                          />
                                        </div>
                                      </CardContent>
                                    </Card>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                        {addingToColumn === column.id ? (
                          <div className="mt-3">
                            <Input
                              type="text"
                              placeholder="Ingrese el contenido de la tarea"
                              value={newTaskContent}
                              onChange={(e) =>
                                setNewTaskContent(e.target.value)
                              }
                              className="mb-2"
                            />
                            <div className="flex space-x-2">
                              <Button
                                onClick={() => addNewTask(column.id)}
                                className="bg-blue-500 hover:bg-blue-600 text-white"
                              >
                                Añadir
                              </Button>
                              <Button
                                onClick={() => setAddingToColumn(null)}
                                variant="outline"
                              >
                                Cancelar
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <Button
                            onClick={() => setAddingToColumn(column.id)}
                            className="mt-3 w-full bg-blue-500 hover:bg-blue-600 text-white"
                          >
                            <Plus className="mr-2 h-4 w-4" /> Añadir tarea
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
            <div className="w-80 flex-shrink-0">
              <Button
                onClick={addNewColumn}
                className="w-full h-12 bg-gray-200 hover:bg-gray-300 text-gray-700"
              >
                <Plus className="mr-2 h-6 w-6" /> Añadir columna
              </Button>
            </div>
          </div>
        )}
      </Droppable>
    </main>
  );
}
