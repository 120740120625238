import { Dependencies } from "..";
export const build = ({ http, abortController }: Dependencies) => {
  const PATH = "/uploads/file/";
  
  const execute = (userID: string, file: File) => {
    const controller = abortController();
    const formData = new FormData();
    formData.append("file", file);

    return {
      response: http.post<any>(`${PATH}${userID}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      controller,
    };
  };

  return execute;
};
