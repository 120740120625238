import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import {
  DollarSign,
  Package,
  FileText,
  PieChart,
  ArrowRight,
  CreditCard,
  Users,
  Briefcase,
  BookOpen,
  Calculator,
  Globe,
} from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import Navbar from "@/components/narbar/narbar";

export default function AccountingModule() {
  const accountingModules = [
    {
      title: "Facturación Electrónica",
      description:
        "Genera y gestiona facturas electrónicas según la normativa DIAN",
      icon: CreditCard,
      path: "/contabilidad/facturacion-electronica",
      color: "bg-blue-500",
    },
    {
      title: "Compras y Ventas",
      description: "Gestiona facturas, órdenes de compra y ventas",
      icon: DollarSign,
      path: "/contabilidad/facturacion",
      color: "bg-green-500",
    },
    {
      title: "Inventario",
      description: "Controla el stock y los movimientos de productos",
      icon: Package,
      path: "/contabilidad/inventario",
      color: "bg-yellow-500",
    },
    {
      title: "Impuestos",
      description:
        "Administra declaraciones y pagos de impuestos (IVA, Retención, ICA)",
      icon: FileText,
      path: "/contabilidad/impuestos",
      color: "bg-red-500",
    },
    {
      title: "Informes Financieros",
      description: "Genera y analiza reportes financieros",
      icon: PieChart,
      path: "/contabilidad/informes",
      color: "bg-purple-500",
    },
    {
      title: "Nómina",
      description:
        "Gestiona pagos, prestaciones y seguridad social de empleados",
      icon: Users,
      path: "/contabilidad/nomina",
      color: "bg-indigo-500",
    },
    {
      title: "Activos Fijos",
      description: "Controla y deprecia los activos de la empresa",
      icon: Briefcase,
      path: "/contabilidad/activos-fijos",
      color: "bg-orange-500",
    },
    {
      title: "Libro Diario",
      description: "Registra las transacciones diarias de la empresa",
      icon: BookOpen,
      path: "/contabilidad/libro-diario",
      color: "bg-teal-500",
    },
    {
      title: "Presupuestos",
      description: "Crea y gestiona presupuestos empresariales",
      icon: Calculator,
      path: "/contabilidad/presupuestos",
      color: "bg-pink-500",
    },
    {
      title: "Informes NIIF",
      description:
        "Genera informes según Normas Internacionales de Información Financiera",
      icon: Globe,
      path: "/contabilidad/informes-niif",
      color: "bg-cyan-500",
    },
  ];

  return (
    <>
      <Navbar />
      <div className="min-h-screen bg-gradient-to-br from-gray-100 to-gray-200 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-center mb-12"
          >
            <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              Módulo de Contabilidad
            </h1>
          </motion.div>

          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {accountingModules.map((module, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
              >
                <Card className="h-full transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg">
                  <CardHeader>
                    <div
                      className={`w-16 h-16 ${module.color} rounded-full flex items-center justify-center mb-4`}
                    >
                      <module.icon className="h-8 w-8 text-white" />
                    </div>
                    <CardTitle className="text-2xl font-bold">
                      {module.title}
                    </CardTitle>
                    <CardDescription className="text-gray-600">
                      {module.description}
                    </CardDescription>
                  </CardHeader>
                  <CardContent>
                    <Button asChild className="w-full group" variant="outline">
                      <Link
                        to={module.path}
                        className="flex items-center justify-center gap-2"
                      >
                        Ir al módulo
                        <ArrowRight className="h-4 w-4 transition-transform duration-300 ease-in-out group-hover:translate-x-1" />
                      </Link>
                    </Button>
                  </CardContent>
                </Card>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
