import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Progress } from "@/components/ui/progress";
import { Search, Plus, Edit, Trash2 } from "lucide-react";
import Navbar from "@/components/narbar/narbar";

type TrainingProgram = {
  id: number;
  name: string;
  description: string;
  duration: string;
  status: "active" | "inactive";
};

type Course = {
  id: number;
  name: string;
  instructor: string;
  duration: string;
  programId: number;
};

type EmployeeTraining = {
  id: number;
  employeeName: string;
  courseId: number;
  progress: number;
  status: "not started" | "in progress" | "completed";
};

const initialTrainingPrograms: TrainingProgram[] = [
  {
    id: 1,
    name: "Onboarding",
    description: "New employee orientation program",
    duration: "1 week",
    status: "active",
  },
  {
    id: 2,
    name: "Leadership Skills",
    description: "Developing leadership capabilities",
    duration: "4 weeks",
    status: "active",
  },
];

const initialCourses: Course[] = [
  {
    id: 1,
    name: "Company Policies",
    instructor: "HR Manager",
    duration: "2 hours",
    programId: 1,
  },
  {
    id: 2,
    name: "Effective Communication",
    instructor: "John Doe",
    duration: "3 days",
    programId: 2,
  },
];

const initialEmployeeTrainings: EmployeeTraining[] = [
  {
    id: 1,
    employeeName: "Alice Johnson",
    courseId: 1,
    progress: 100,
    status: "completed",
  },
  {
    id: 2,
    employeeName: "Bob Williams",
    courseId: 2,
    progress: 50,
    status: "in progress",
  },
];

export default function Training() {
  const [trainingPrograms, setTrainingPrograms] = useState<TrainingProgram[]>(
    initialTrainingPrograms
  );
  const [courses, setCourses] = useState<Course[]>(initialCourses);
  const [employeeTrainings, setEmployeeTrainings] = useState<
    EmployeeTraining[]
  >(initialEmployeeTrainings);
  const [searchTerm, setSearchTerm] = useState("");
  const [newProgram, setNewProgram] = useState<Omit<TrainingProgram, "id">>({
    name: "",
    description: "",
    duration: "",
    status: "active",
  });
  const [newCourse, setNewCourse] = useState<Omit<Course, "id">>({
    name: "",
    instructor: "",
    duration: "",
    programId: 0,
  });
  const [newEmployeeTraining, setNewEmployeeTraining] = useState<
    Omit<EmployeeTraining, "id">
  >({ employeeName: "", courseId: 0, progress: 0, status: "not started" });
  const [editingProgram, setEditingProgram] = useState<TrainingProgram | null>(
    null
  );
  const [editingCourse, setEditingCourse] = useState<Course | null>(null);
  const [editingEmployeeTraining, setEditingEmployeeTraining] =
    useState<EmployeeTraining | null>(null);

  const filteredPrograms = trainingPrograms.filter((program) =>
    program.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredCourses = courses.filter((course) =>
    course.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredEmployeeTrainings = employeeTrainings.filter((training) =>
    training.employeeName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAddProgram = () => {
    setTrainingPrograms([
      ...trainingPrograms,
      { ...newProgram, id: trainingPrograms.length + 1 },
    ]);
    setNewProgram({
      name: "",
      description: "",
      duration: "",
      status: "active",
    });
  };

  const handleEditProgram = () => {
    if (editingProgram) {
      setTrainingPrograms(
        trainingPrograms.map((program) =>
          program.id === editingProgram.id ? editingProgram : program
        )
      );
      setEditingProgram(null);
    }
  };

  const handleDeleteProgram = (id: number) => {
    setTrainingPrograms(
      trainingPrograms.filter((program) => program.id !== id)
    );
  };

  const handleAddCourse = () => {
    setCourses([...courses, { ...newCourse, id: courses.length + 1 }]);
    setNewCourse({ name: "", instructor: "", duration: "", programId: 0 });
  };

  const handleEditCourse = () => {
    if (editingCourse) {
      setCourses(
        courses.map((course) =>
          course.id === editingCourse.id ? editingCourse : course
        )
      );
      setEditingCourse(null);
    }
  };

  const handleDeleteCourse = (id: number) => {
    setCourses(courses.filter((course) => course.id !== id));
  };

  const handleAddEmployeeTraining = () => {
    setEmployeeTrainings([
      ...employeeTrainings,
      { ...newEmployeeTraining, id: employeeTrainings.length + 1 },
    ]);
    setNewEmployeeTraining({
      employeeName: "",
      courseId: 0,
      progress: 0,
      status: "not started",
    });
  };

  const handleEditEmployeeTraining = () => {
    if (editingEmployeeTraining) {
      setEmployeeTrainings(
        employeeTrainings.map((training) =>
          training.id === editingEmployeeTraining.id
            ? editingEmployeeTraining
            : training
        )
      );
      setEditingEmployeeTraining(null);
    }
  };

  const handleDeleteEmployeeTraining = (id: number) => {
    setEmployeeTrainings(
      employeeTrainings.filter((training) => training.id !== id)
    );
  };

  return (
    <div>
      <Navbar />
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">Módulo de Capacitación</h1>

        <div className="flex justify-between items-center mb-4">
          <div className="relative w-64">
            <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-500" />
            <Input
              type="text"
              placeholder="Buscar..."
              className="pl-8"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        <Tabs defaultValue="programs" className="space-y-4">
          <TabsList>
            <TabsTrigger value="programs">
              Programas de Capacitación
            </TabsTrigger>
            <TabsTrigger value="courses">Cursos</TabsTrigger>
            <TabsTrigger value="employee-trainings">
              Capacitaciones de Empleados
            </TabsTrigger>
          </TabsList>

          <TabsContent value="programs">
            <Card>
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle>Programas de Capacitación</CardTitle>
                <Dialog>
                  <DialogTrigger asChild>
                    <Button>
                      <Plus className="mr-2 h-4 w-4" /> Nuevo Programa
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>
                        Agregar Nuevo Programa de Capacitación
                      </DialogTitle>
                    </DialogHeader>
                    <div className="grid gap-4 py-4">
                      <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="name" className="text-right">
                          Nombre
                        </Label>
                        <Input
                          id="name"
                          className="col-span-3"
                          value={newProgram.name}
                          onChange={(e) =>
                            setNewProgram({
                              ...newProgram,
                              name: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="description" className="text-right">
                          Descripción
                        </Label>
                        <Textarea
                          id="description"
                          className="col-span-3"
                          value={newProgram.description}
                          onChange={(e) =>
                            setNewProgram({
                              ...newProgram,
                              description: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="duration" className="text-right">
                          Duración
                        </Label>
                        <Input
                          id="duration"
                          className="col-span-3"
                          value={newProgram.duration}
                          onChange={(e) =>
                            setNewProgram({
                              ...newProgram,
                              duration: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="status" className="text-right">
                          Estado
                        </Label>
                        <Select
                          onValueChange={(value) =>
                            setNewProgram({
                              ...newProgram,
                              status: value as "active" | "inactive",
                            })
                          }
                        >
                          <SelectTrigger className="col-span-3">
                            <SelectValue placeholder="Seleccionar estado" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="active">Activo</SelectItem>
                            <SelectItem value="inactive">Inactivo</SelectItem>
                          </SelectContent>
                        </Select>
                      </div>
                    </div>
                    <Button onClick={handleAddProgram}>Agregar Programa</Button>
                  </DialogContent>
                </Dialog>
              </CardHeader>
              <CardContent>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Nombre</TableHead>
                      <TableHead>Descripción</TableHead>
                      <TableHead>Duración</TableHead>
                      <TableHead>Estado</TableHead>
                      <TableHead>Acciones</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {filteredPrograms.map((program) => (
                      <TableRow key={program.id}>
                        <TableCell className="font-medium">
                          {program.name}
                        </TableCell>
                        <TableCell>{program.description}</TableCell>
                        <TableCell>{program.duration}</TableCell>
                        <TableCell>
                          <Badge
                            variant={
                              program.status === "active"
                                ? "default"
                                : "secondary"
                            }
                          >
                            {program.status === "active"
                              ? "Activo"
                              : "Inactivo"}
                          </Badge>
                        </TableCell>
                        <TableCell>
                          <div className="flex space-x-2">
                            <Dialog>
                              <DialogTrigger asChild>
                                <Button
                                  variant="outline"
                                  size="icon"
                                  onClick={() => setEditingProgram(program)}
                                >
                                  <Edit className="h-4 w-4" />
                                </Button>
                              </DialogTrigger>
                              <DialogContent>
                                <DialogHeader>
                                  <DialogTitle>
                                    Editar Programa de Capacitación
                                  </DialogTitle>
                                </DialogHeader>
                                {editingProgram && (
                                  <div className="grid gap-4 py-4">
                                    <div className="grid grid-cols-4 items-center gap-4">
                                      <Label
                                        htmlFor="edit-name"
                                        className="text-right"
                                      >
                                        Nombre
                                      </Label>
                                      <Input
                                        id="edit-name"
                                        className="col-span-3"
                                        value={editingProgram.name}
                                        onChange={(e) =>
                                          setEditingProgram({
                                            ...editingProgram,
                                            name: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                    <div className="grid grid-cols-4 items-center gap-4">
                                      <Label
                                        htmlFor="edit-description"
                                        className="text-right"
                                      >
                                        Descripción
                                      </Label>
                                      <Textarea
                                        id="edit-description"
                                        className="col-span-3"
                                        value={editingProgram.description}
                                        onChange={(e) =>
                                          setEditingProgram({
                                            ...editingProgram,
                                            description: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                    <div className="grid grid-cols-4 items-center gap-4">
                                      <Label
                                        htmlFor="edit-duration"
                                        className="text-right"
                                      >
                                        Duración
                                      </Label>
                                      <Input
                                        id="edit-duration"
                                        className="col-span-3"
                                        value={editingProgram.duration}
                                        onChange={(e) =>
                                          setEditingProgram({
                                            ...editingProgram,
                                            duration: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                    <div className="grid grid-cols-4 items-center gap-4">
                                      <Label
                                        htmlFor="edit-status"
                                        className="text-right"
                                      >
                                        Estado
                                      </Label>
                                      <Select
                                        onValueChange={(value) =>
                                          setEditingProgram({
                                            ...editingProgram,
                                            status: value as
                                              | "active"
                                              | "inactive",
                                          })
                                        }
                                      >
                                        <SelectTrigger className="col-span-3">
                                          <SelectValue
                                            placeholder={editingProgram.status}
                                          />
                                        </SelectTrigger>
                                        <SelectContent>
                                          <SelectItem value="active">
                                            Activo
                                          </SelectItem>
                                          <SelectItem value="inactive">
                                            Inactivo
                                          </SelectItem>
                                        </SelectContent>
                                      </Select>
                                    </div>
                                  </div>
                                )}
                                <Button onClick={handleEditProgram}>
                                  Guardar Cambios
                                </Button>
                              </DialogContent>
                            </Dialog>
                            <Button
                              variant="outline"
                              size="icon"
                              onClick={() => handleDeleteProgram(program.id)}
                            >
                              <Trash2 className="h-4 w-4" />
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </TabsContent>

          <TabsContent value="courses">
            <Card>
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle>Cursos</CardTitle>
                <Dialog>
                  <DialogTrigger asChild>
                    <Button>
                      <Plus className="mr-2 h-4 w-4" /> Nuevo Curso
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>Agregar Nuevo Curso</DialogTitle>
                    </DialogHeader>
                    <div className="grid gap-4 py-4">
                      <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="course-name" className="text-right">
                          Nombre
                        </Label>
                        <Input
                          id="course-name"
                          className="col-span-3"
                          value={newCourse.name}
                          onChange={(e) =>
                            setNewCourse({ ...newCourse, name: e.target.value })
                          }
                        />
                      </div>
                      <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="instructor" className="text-right">
                          Instructor
                        </Label>
                        <Input
                          id="instructor"
                          className="col-span-3"
                          value={newCourse.instructor}
                          onChange={(e) =>
                            setNewCourse({
                              ...newCourse,
                              instructor: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="course-duration" className="text-right">
                          Duración
                        </Label>
                        <Input
                          id="course-duration"
                          className="col-span-3"
                          value={newCourse.duration}
                          onChange={(e) =>
                            setNewCourse({
                              ...newCourse,
                              duration: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="program" className="text-right">
                          Programa
                        </Label>
                        <Select
                          onValueChange={(value) =>
                            setNewCourse({
                              ...newCourse,
                              programId: parseInt(value),
                            })
                          }
                        >
                          <SelectTrigger className="col-span-3">
                            <SelectValue placeholder="Seleccionar programa" />
                          </SelectTrigger>
                          <SelectContent>
                            {trainingPrograms.map((program) => (
                              <SelectItem
                                key={program.id}
                                value={program.id.toString()}
                              >
                                {program.name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </div>
                    </div>
                    <Button onClick={handleAddCourse}>Agregar Curso</Button>
                  </DialogContent>
                </Dialog>
              </CardHeader>
              <CardContent>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Nombre</TableHead>
                      <TableHead>Instructor</TableHead>
                      <TableHead>Duración</TableHead>
                      <TableHead>Programa</TableHead>
                      <TableHead>Acciones</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {filteredCourses.map((course) => (
                      <TableRow key={course.id}>
                        <TableCell className="font-medium">
                          {course.name}
                        </TableCell>
                        <TableCell>{course.instructor}</TableCell>
                        <TableCell>{course.duration}</TableCell>
                        <TableCell>
                          {
                            trainingPrograms.find(
                              (p) => p.id === course.programId
                            )?.name
                          }
                        </TableCell>
                        <TableCell>
                          <div className="flex space-x-2">
                            <Dialog>
                              <DialogTrigger asChild>
                                <Button
                                  variant="outline"
                                  size="icon"
                                  onClick={() => setEditingCourse(course)}
                                >
                                  <Edit className="h-4 w-4" />
                                </Button>
                              </DialogTrigger>
                              <DialogContent>
                                <DialogHeader>
                                  <DialogTitle>Editar Curso</DialogTitle>
                                </DialogHeader>
                                {editingCourse && (
                                  <div className="grid gap-4 py-4">
                                    <div className="grid grid-cols-4 items-center gap-4">
                                      <Label
                                        htmlFor="edit-course-name"
                                        className="text-right"
                                      >
                                        Nombre
                                      </Label>
                                      <Input
                                        id="edit-course-name"
                                        className="col-span-3"
                                        value={editingCourse.name}
                                        onChange={(e) =>
                                          setEditingCourse({
                                            ...editingCourse,
                                            name: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                    <div className="grid grid-cols-4 items-center gap-4">
                                      <Label
                                        htmlFor="edit-instructor"
                                        className="text-right"
                                      >
                                        Instructor
                                      </Label>
                                      <Input
                                        id="edit-instructor"
                                        className="col-span-3"
                                        value={editingCourse.instructor}
                                        onChange={(e) =>
                                          setEditingCourse({
                                            ...editingCourse,
                                            instructor: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                    <div className="grid grid-cols-4 items-center gap-4">
                                      <Label
                                        htmlFor="edit-course-duration"
                                        className="text-right"
                                      >
                                        Duración
                                      </Label>
                                      <Input
                                        id="edit-course-duration"
                                        className="col-span-3"
                                        value={editingCourse.duration}
                                        onChange={(e) =>
                                          setEditingCourse({
                                            ...editingCourse,
                                            duration: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                    <div className="grid grid-cols-4 items-center gap-4">
                                      <Label
                                        htmlFor="edit-program"
                                        className="text-right"
                                      >
                                        Programa
                                      </Label>
                                      <Select
                                        onValueChange={(value) =>
                                          setEditingCourse({
                                            ...editingCourse,
                                            programId: parseInt(value),
                                          })
                                        }
                                      >
                                        <SelectTrigger className="col-span-3">
                                          <SelectValue
                                            placeholder={
                                              trainingPrograms.find(
                                                (p) =>
                                                  p.id ===
                                                  editingCourse.programId
                                              )?.name
                                            }
                                          />
                                        </SelectTrigger>
                                        <SelectContent>
                                          {trainingPrograms.map((program) => (
                                            <SelectItem
                                              key={program.id}
                                              value={program.id.toString()}
                                            >
                                              {program.name}
                                            </SelectItem>
                                          ))}
                                        </SelectContent>
                                      </Select>
                                    </div>
                                  </div>
                                )}
                                <Button onClick={handleEditCourse}>
                                  Guardar Cambios
                                </Button>
                              </DialogContent>
                            </Dialog>
                            <Button
                              variant="outline"
                              size="icon"
                              onClick={() => handleDeleteCourse(course.id)}
                            >
                              <Trash2 className="h-4 w-4" />
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </TabsContent>

          <TabsContent value="employee-trainings">
            <Card>
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle>Capacitaciones de Empleados</CardTitle>
                <Dialog>
                  <DialogTrigger asChild>
                    <Button>
                      <Plus className="mr-2 h-4 w-4" /> Nueva Capacitación
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>Asignar Nueva Capacitación</DialogTitle>
                    </DialogHeader>
                    <div className="grid gap-4 py-4">
                      <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="employee-name" className="text-right">
                          Empleado
                        </Label>
                        <Input
                          id="employee-name"
                          className="col-span-3"
                          value={newEmployeeTraining.employeeName}
                          onChange={(e) =>
                            setNewEmployeeTraining({
                              ...newEmployeeTraining,
                              employeeName: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="course" className="text-right">
                          Curso
                        </Label>
                        <Select
                          onValueChange={(value) =>
                            setNewEmployeeTraining({
                              ...newEmployeeTraining,
                              courseId: parseInt(value),
                            })
                          }
                        >
                          <SelectTrigger className="col-span-3">
                            <SelectValue placeholder="Seleccionar curso" />
                          </SelectTrigger>
                          <SelectContent>
                            {courses.map((course) => (
                              <SelectItem
                                key={course.id}
                                value={course.id.toString()}
                              >
                                {course.name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </div>
                    </div>
                    <Button onClick={handleAddEmployeeTraining}>
                      Asignar Capacitación
                    </Button>
                  </DialogContent>
                </Dialog>
              </CardHeader>
              <CardContent>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Empleado</TableHead>
                      <TableHead>Curso</TableHead>
                      <TableHead>Progreso</TableHead>
                      <TableHead>Estado</TableHead>
                      <TableHead>Acciones</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {filteredEmployeeTrainings.map((training) => (
                      <TableRow key={training.id}>
                        <TableCell className="font-medium">
                          {training.employeeName}
                        </TableCell>
                        <TableCell>
                          {
                            courses.find((c) => c.id === training.courseId)
                              ?.name
                          }
                        </TableCell>
                        <TableCell>
                          <Progress
                            value={training.progress}
                            className="w-[60%]"
                          />
                        </TableCell>
                        <TableCell>
                          <Badge
                            variant={
                              training.status === "completed"
                                ? "default"
                                : "secondary"
                            }
                          >
                            {training.status}
                          </Badge>
                        </TableCell>
                        <TableCell>
                          <div className="flex space-x-2">
                            <Dialog>
                              <DialogTrigger asChild>
                                <Button
                                  variant="outline"
                                  size="icon"
                                  onClick={() =>
                                    setEditingEmployeeTraining(training)
                                  }
                                >
                                  <Edit className="h-4 w-4" />
                                </Button>
                              </DialogTrigger>
                              <DialogContent>
                                <DialogHeader>
                                  <DialogTitle>
                                    Editar Capacitación de Empleado
                                  </DialogTitle>
                                </DialogHeader>
                                {editingEmployeeTraining && (
                                  <div className="grid gap-4 py-4">
                                    <div className="grid grid-cols-4 items-center gap-4">
                                      <Label
                                        htmlFor="edit-employee-name"
                                        className="text-right"
                                      >
                                        Empleado
                                      </Label>
                                      <Input
                                        id="edit-employee-name"
                                        className="col-span-3"
                                        value={
                                          editingEmployeeTraining.employeeName
                                        }
                                        onChange={(e) =>
                                          setEditingEmployeeTraining({
                                            ...editingEmployeeTraining,
                                            employeeName: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                    <div className="grid grid-cols-4 items-center gap-4">
                                      <Label
                                        htmlFor="edit-course"
                                        className="text-right"
                                      >
                                        Curso
                                      </Label>
                                      <Select
                                        onValueChange={(value) =>
                                          setEditingEmployeeTraining({
                                            ...editingEmployeeTraining,
                                            courseId: parseInt(value),
                                          })
                                        }
                                      >
                                        <SelectTrigger className="col-span-3">
                                          <SelectValue
                                            placeholder={
                                              courses.find(
                                                (c) =>
                                                  c.id ===
                                                  editingEmployeeTraining.courseId
                                              )?.name
                                            }
                                          />
                                        </SelectTrigger>
                                        <SelectContent>
                                          {courses.map((course) => (
                                            <SelectItem
                                              key={course.id}
                                              value={course.id.toString()}
                                            >
                                              {course.name}
                                            </SelectItem>
                                          ))}
                                        </SelectContent>
                                      </Select>
                                    </div>
                                    <div className="grid grid-cols-4 items-center gap-4">
                                      <Label
                                        htmlFor="edit-progress"
                                        className="text-right"
                                      >
                                        Progreso
                                      </Label>
                                      <Input
                                        id="edit-progress"
                                        type="number"
                                        min="0"
                                        max="100"
                                        className="col-span-3"
                                        value={editingEmployeeTraining.progress}
                                        onChange={(e) =>
                                          setEditingEmployeeTraining({
                                            ...editingEmployeeTraining,
                                            progress: parseInt(e.target.value),
                                          })
                                        }
                                      />
                                    </div>
                                    <div className="grid grid-cols-4 items-center gap-4">
                                      <Label
                                        htmlFor="edit-status"
                                        className="text-right"
                                      >
                                        Estado
                                      </Label>
                                      <Select
                                        onValueChange={(value) =>
                                          setEditingEmployeeTraining({
                                            ...editingEmployeeTraining,
                                            status:
                                              value as EmployeeTraining["status"],
                                          })
                                        }
                                      >
                                        <SelectTrigger className="col-span-3">
                                          <SelectValue
                                            placeholder={
                                              editingEmployeeTraining.status
                                            }
                                          />
                                        </SelectTrigger>
                                        <SelectContent>
                                          <SelectItem value="not started">
                                            No iniciado
                                          </SelectItem>
                                          <SelectItem value="in progress">
                                            En progreso
                                          </SelectItem>
                                          <SelectItem value="completed">
                                            Completado
                                          </SelectItem>
                                        </SelectContent>
                                      </Select>
                                    </div>
                                  </div>
                                )}
                                <Button onClick={handleEditEmployeeTraining}>
                                  Guardar Cambios
                                </Button>
                              </DialogContent>
                            </Dialog>
                            <Button
                              variant="outline"
                              size="icon"
                              onClick={() =>
                                handleDeleteEmployeeTraining(training.id)
                              }
                            >
                              <Trash2 className="h-4 w-4" />
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
}
