/* eslint-disable @typescript-eslint/no-explicit-any */
export type HttpErrorHandler = (error: any) => any;

export const HttpErrorHandler: HttpErrorHandler = (error: any) => {
  if (error.message === "CANCEL") {
    return { message: "Request was cancelled", status: null };
  }

  if (error.code === "ERR_NETWORK" || error.message.includes("Network Error")) {
    return {
      message: "No se pudo establecer la conexión. Verifica tu red.",
      status: null,
    };
  }

  if (
    error.code === "ERR_CONNECTION_REFUSED" ||
    error.message.includes("ERR_CONNECTION_REFUSED")
  ) {
    return {
      message: "La conexión fue rechazada. El servidor no está disponible.",
      status: null,
    };
  }

  // Otros errores de respuesta del servidor
  const { response } = error ?? {};
  if (response) {
    const { data = {}, status = 500 } = response;
    return {
      message: data?.message || "Error inesperado",
      status,
    };
  }

  // Manejo de errores generales
  return {
    message: "Error inesperado",
    status: 500,
  };
};

const service = {
  HttpErrorHandler,
};

export default service;
