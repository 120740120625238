import API_PATHS from "@/constants/api_paths";
import axios, { AxiosInstance } from "axios";
import { abortController } from "@/packages/abort_controller";

import { build as buildLogin } from "@/api/resources/auth/services/login";

export type HttpHandler = AxiosInstance;

export type Dependencies = {
  http: HttpHandler;
  abortController: () => AbortController;
};

const httpAuth = axios.create({
  baseURL: API_PATHS.auth,
});

const dependenciesAuth: Dependencies = {
  http: httpAuth,
  abortController,
};

export const login = buildLogin(dependenciesAuth);

const service = {
  login,
};

export default service;
